import Manager from '@managers/manager'

class ConversationMessage {
  constructor(
    _id = '',
    saved = false,
    _sender = '',
    _recipient = '',
    _timestamp = '',
    _readState = 'delivered',
    _message = '',
    _notificationSent = false,
    _saved = false
  ) {
    this.id = Manager.getUid()
    this.saved = _saved
    this.message = _message
    this.sender = _sender
    this.recipient = _recipient
    this.timestamp = _timestamp
    this.readState = 'delivered'
    this.notificationSent = _notificationSent
  }
}

export default ConversationMessage
