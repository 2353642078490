class Coparent {
  constructor(_name = '', _parentType = '', _phone = '', _address = '', _id = '') {
    this.name = _name
    this.parentType = _parentType
    this.phone = _phone
    this.address = _address
    this.id = _id
  }
}

export default Coparent
