import DB from "@db"
import "../prototypes"
import calMapper from "../mappers/calMapper"

export default CalendarManager =
  getUniqueArrayOfObjects: (arr, key) =>
    output = Object.entries(Object.assign({}, ...arr))
    for obj in output
      obj[1]
  formatEventTitle: (title) =>
    if title and title.length > 0
      title = title.uppercaseFirstLetterOfAllWords()
      return title.replaceAll("To", "to").replaceAll("Vs", "vs").replaceAll("With", "with").replaceAll("At","at")
    return title
  hideCalendar: () =>
    allCals = document.querySelectorAll(".flatpickr-calendar")
    if allCals && allCals.length > 0
      allCals.forEach((cal) => cal.remove())
