import React, { useState, useEffect, useContext } from 'react'
import DB from '@db'
import Modal from '@shared/modal.jsx'
import Manager from '@manager'
import globalState from '../../../context.js'
import 'rsuite/dist/rsuite.min.css'
import moment from 'moment'
import AddNewButton from '@shared/addNewButton.jsx'
import ScreenNames from '@screenNames'
import { getDatabase, ref, set, get, child, onValue, push, remove } from 'firebase/database'
import NotificationManager from '@managers/notificationManager.js'
import { useSwipeable } from 'react-swipeable'
import ChatManager from '@managers/chatManager.js'
import DB_UserScoped from '@userScoped'
import Confirm from 'components/shared/confirm.jsx'

const Chats = () => {
  const { state, setState } = useContext(globalState)
  const { currentUser } = state
  const [showNewThreadForm, setShowNewThreadForm] = useState(false)
  const [threads, setThreads] = useState([])
  const [confirmMessage, setConfirmMessage] = useState('')
  const [selectedCoparent, setSelectedCoparent] = useState(null)

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      document.querySelector('.delete-button').classList.add('active')
    },
    onSwipedRight: () => {
      document.querySelector('.delete-button').classList.remove('active')
    },
    preventScrollOnSwipe: true,
  })

  const openMessageThread = async (coparentPhone) => {
    const userCoparent = await DB_UserScoped.getCoparent(coparentPhone, currentUser)
    setState({ ...state, currentScreen: ScreenNames.conversation, messageToUser: userCoparent })
  }

  const getChats = async () => {
    let scopedChats = await ChatManager.getChats(currentUser)
    setThreads(scopedChats.flat())
  }

  const setInboxUnreadCount = async () => {
    const coparentElements = document.querySelectorAll('[data-coparent-phone]')
    const dbRef = ref(getDatabase())

    await get(child(dbRef, `${DB.tables.users}`)).then((users) => {
      const usersVal = users.val()
      const usersWithInbox = DB.convertKeyObjectToArray(usersVal).filter((x) => x.chats !== undefined)
      coparentElements.forEach(async (el, elIndex) => {
        const coparentPhone = el.getAttribute('data-coparent-phone')

        usersWithInbox.forEach((userWithInbox, index) => {
          const threads = DB.convertKeyObjectToArray(userWithInbox.chats)
            .filter((x) => x.hasOwnProperty('members'))
            .flat()
          let threadToUse
          threads.forEach((thread) => {
            const members = thread.members
            const currentUserIsValidMember = members.filter((x) => x.phone === currentUser.phone)
            const coparentIsValidMember = members.filter((x) => x.phone === coparentPhone)
            if (currentUserIsValidMember.length > 0 && coparentIsValidMember.length > 0) {
              threadToUse = thread
            }
          })
          if (Manager.variableIsValid(threadToUse, false, true) && Manager.variableIsValid(threadToUse.messages, true)) {
            let messages = threadToUse.messages.filter(
              (x) => x.readState === 'delivered' && x.toName === currentUser.name && x.fromName !== currentUser.name
            )
            const countSpan = el.nextSibling
            if (messages.length > 0) {
              if (countSpan && countSpan !== undefined && messages.length > 0) {
                el.nextSibling.innerText = messages.length
                countSpan.classList.add('active')
              }
            } else {
              countSpan.style.display = 'none'
            }
            setState({ ...state, unreadMessages: messages.length })
          }
        })
      })
    })
  }

  const archive = async () => {
    if (Manager.variableIsValid(selectedCoparent)) {
      await ChatManager.deleteAndArchive(currentUser, selectedCoparent)
      setConfirmMessage('')
      getChats()
    }
  }

  useEffect(() => {
    setState({
      ...state,
      currentScreen: ScreenNames.chats,
      menuIsOpen: false,
      showMenuButton: true,
      showBackButton: false,
    })

    getChats()
    Manager.toggleForModalOrNewForm('show')
  }, [])

  return (
    <>
      <p className="screen-title ">Chats</p>
      <Confirm
        onAccept={archive}
        onReject={() => setConfirmMessage('')}
        message={confirmMessage}
        subtitle={'Are you sure? If you delete this message, it will be archived. However, you can submit a request to view it.'}
      />
      {!showNewThreadForm && (
        <AddNewButton
          scopedClass={'chats'}
          onClick={() => {
            setShowNewThreadForm(true)
            setState({ ...state, currentScreenTitle: 'New Message' })
          }}
          icon={'add_comment'}
        />
      )}
      <div id="chats-container" className="page-container">
        {/* THREAD LINE ITEM */}
        {!showNewThreadForm &&
          threads.length > 0 &&
          threads.map((thread, index) => {
            const coparent = thread.members.filter((x) => x.phone !== currentUser.phone)[0]
            return (
              <div
                key={Manager.getUid()}
                {...handlers}
                className="flex thread-item"
                onClick={(e) => {
                  if (!e.target.classList.contains('delete-button')) {
                    openMessageThread(coparent.phone)
                  }
                }}>
                <span className="material-icons profile-pic">account_circle</span>
                <p data-coparent-phone={coparent.phone} className="thread-name">
                  {coparent.name.formatNameFirstNameOnly()}
                </p>
                <span className="unread-count"></span>
                <button
                  className="delete-button button"
                  onClick={() => {
                    setConfirmMessage(`DELETING CONVERSATION WITH ${coparent.name.getFirstWord()}`)
                    setSelectedCoparent(coparent)
                  }}>
                  DELETE
                </button>
              </div>
            )
          })}

        {!showNewThreadForm && threads.length === 0 && <p className="instructions center">There are currently no conversations 🤷🏽‍♂️</p>}

        {/* NEW THREAD FORM */}
        {showNewThreadForm && <label>To who?</label>}
        {showNewThreadForm &&
          currentUser &&
          currentUser.coparents.map((coparent, index) => {
            return (
              <p key={index} className="coparent-name thread-item" onClick={() => openMessageThread(coparent.phone)}>
                {coparent.name} <span className="material-icons">arrow_forward_ios</span>
              </p>
            )
          })}
      </div>
    </>
  )
}

export default Chats
