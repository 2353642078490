import Manager from '@manager'
import { createWorker } from 'tesseract.js'

const DocManager = {
  tocHeaders: [
    'child-support',
    'spousal-maintenance',
    'matrimonial-home',
    'assets',
    'debts',
    'equitable-distribution-release',
    'dower-curtesy-and-homestead-release',
    'between',
    'background',
    'living-separate-and-apart',
    'interference',
    'children',
    'child-custody',
    'estate-and-testamentary-disposition',
    'pension-release',
    'general-release',
    'general-provisions',
    'acknowledgement',
    'division-of-property',
    'real-estate',
    'household-goods-and-furnishings',
    'motor-vehicles',
    'financial-accounts',
    'incomes-taxes',
    'definitions',
    'the-distribution',
    'mutual-releases-indemnification-and-litigation',
    'custody-and-visitation',
    'spousal-support',
    'the-family-residence',
    'retirement-benefits',
    'husbands-separate-property',
    'wifes-separate-property',
    'severability-and-enforceability',
    'law-applicable',
  ],
  nodeApiUrl: () => {
    if (location.hostname === 'localhost') {
      return 'http://localhost:8888/.netlify/functions/app/'
    } else {
      return 'https://pcp-node.netlify.app/.netlify/functions/app/'
    }
  },
  hasNumbers: (str) => {
    var regex = /\d/g
    return regex.test(str)
  },
  cleanHeader: (header, uppercaseAll = false, uppercaseFirstWord) => {
    let returnString = header.replaceAll('-', ' ')
    if (uppercaseAll) {
      returnString = returnString.toUpperCase()
    }
    if (uppercaseFirstWord) {
      returnString = Manager.uppercaseFirstLetterInWord
    }
    return returnString.replaceAll("'", '')
  },
  formatDocHeaders: (text) => {
    DocManager.tocHeaders.forEach((header) => {
      text = text.replaceAll(
        DocManager.cleanHeader(header, true),
        `<span data-header-name="${header.replaceAll("'", '&apos;')}" className="header">${DocManager.cleanHeader(header)}</span>`
      )
    })
    return text
  },
  textToImageAndAppend: async (imagePath, textContainer) => {
    const worker = await createWorker()
    await worker.recognize(imagePath).then((result) => {
      let confidence = result.confidence
      let paragraphs = result.data.paragraphs

      paragraphs.forEach((par) => {
        if (DocManager.hasNumbers(par.text) && par.text.trim().split(/\s+/).length <= 10) {
          par.text = `<span className="sub-header">${par.text}</span>`
        }
        const parEl = document.createElement('p')
        par.text = DocManager.formatDocHeaders(par.text)

        parEl.innerHTML = par.text
        textContainer.appendChild(parEl)
      })
    })
  },
  addHeaderClass: (el) => {
    let strong = el.querySelector('strong')

    if (el.tagName.toLowerCase() === 'strong') {
      if (Manager.variableIsValid(el)) {
        const strongText = el.textContent
        const hasNumbers = strongText.indexOf('.') > -1
        if (hasNumbers && strongText.wordCount() < 6 && strongText.length > 3) {
          el.parentNode.classList.add('header')
        }
        if (strongText.wordCount() <= 6 && strongText.length > 5) {
          if (strongText.toLowerCase().indexOf('state') === -1 && strongText.toLowerCase().indexOf('county') === -1) {
            el.parentNode.classList.add('header')
          } else {
            el.parentNode.classList.remove('header')
          }
        }
      }
    } else {
      if (Manager.variableIsValid(strong)) {
        const strongText = strong.textContent
        const hasNumbers = strongText.indexOf('.') > -1
        if (hasNumbers && strongText.wordCount() < 6 && strongText.length > 3) {
          el.classList.add('header')
        }
        if (strongText.wordCount() <= 6 && strongText.length > 5) {
          if (strongText.toLowerCase().indexOf('state') === -1 && strongText.toLowerCase().indexOf('county') === -1) {
            el.classList.add('header')
          }
        }
      }
    }
  },
}

export default DocManager
