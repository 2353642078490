export default MenuMapper =
  stringToIconName: (inputString) ->
    if inputString.toLowerCase().contains "calendar"
      return "calendar_month"
    if inputString.toLowerCase().contains "coparenting"
      return  "meeting_room"
    if inputString.toLowerCase().contains "chats"
      return  "question_answer"
    if inputString.toLowerCase().contains "expense"
      return  "paid"
    if inputString.toLowerCase().contains "swap"
      return  "swap_horizontal_circle"
    if inputString.toLowerCase().contains "transfer"
      return  "update"
    if inputString.toLowerCase().contains "documents"
      return  "description"
    if inputString.toLowerCase().contains "child"
      return  "face"
    if inputString.toLowerCase().contains "memories"
      return  "collections"
    if inputString.toLowerCase().contains "coparents"
      return  "family_restroom"
    if inputString.toLowerCase().contains "account"
      return  "manage_accounts"
    if inputString.toLowerCase().contains "settings"
      return  "settings"