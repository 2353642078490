import React, { useContext, useEffect, useRef, useState } from 'react'
import DB from '@db'
import FirebaseStorage from '@firebaseStorage'
import ImageManager from '@managers/imageManager'
import Manager from '@manager'
import AddNewButton from '@shared/addNewButton'
import ScreenNames from 'constants/screenNames'
import globalState from '../../context'
import { useSwipeable } from '../../../node_modules/react-swipeable/es/index'
import PopupCard from 'components/shared/popupCard'

export default function Memories() {
  const { state, setState } = useContext(globalState)
  const { currentUser } = state
  const [memories, setMemories] = useState([])
  const [showImagePopup, setShowImagePopup] = useState(false)
  const [imgArray, setImgArray] = useState([])
  const [popupImageIndex, setPopupImageIndex] = useState(0)
  const inputFile = useRef(null)
  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      // navigateToImage('forward')
      setPopupImageIndex((popupImageIndex) => popupImageIndex - 1)
    },
    onSwipedRight: () => {
      // navigateToImage('back')
      setPopupImageIndex((popupImageIndex) => popupImageIndex + 1)
    },
  })

  const expandImage = (e) => {
    // console.log(e)
    // const clickedMemory = e.target;
    // const clickedMemoryId = clickedMemory.getAttribute('data-id')
    // const contentContainers = document.querySelectorAll('#img-content-container')
    // const memoryContainers = document.querySelectorAll('.memory-container')
    // contentContainers.forEach((container) => {
    //   let dataId = container.getAttribute('data-id')
    //   console.log(dataId)
    // })
    setShowImagePopup(true)
  }

  const getImages = async () => {
    setState({ ...state, isLoading: true })
    let all = await DB.getAllFilteredRecords(DB.tables.users, currentUser, 'memories')
    const resolvedImages = async () =>
      await new Promise(async (resolve) => {
        let promises = []
        for (const memory of all) {
          promises.push(FirebaseStorage.imageExists(memory.url, memory))

          // const imageName = FirebaseStorage.getImageNameFromUrl(memory.url)
          // console.log(imageName)
          // await FirebaseStorage.delete(FirebaseStorage.directories.memories, memory.id, imageName, memory)
          // console.log(exists)
        }
        Promise.all(promises).then((results) => {
          let toReturn = results.map((x) => x.successful).flat()
          let toDelete = results.map((x) => x.failed).filter((x) => x !== undefined)
          toReturn = toReturn.filter((x) => x !== undefined)
          toDelete.forEach((memoryToDelete) => {
            deleteMemory(memoryToDelete.url, memoryToDelete)
          })
          resolve(toReturn)
        })
      })
    const validImages = await resolvedImages()
    if (currentUser) {
      if (validImages && validImages.length > 0) {
        let arr = []
        validImages.forEach((img) => {
          arr.push({
            id: img.id,
            notes: img.notes,
            url: img.url,
          })
        })
        setImgArray(arr)
        setMemories(validImages)
        setState({ ...state, isLoading: false })
        setTimeout(() => {
          addImageAnimation()
        }, 200)
        Manager.toggleForModalOrNewForm('show')
      } else {
        setMemories([])
        Manager.toggleForModalOrNewForm('show')
      }
    }
    // setState({ ...state, isLoading: false })
  }

  const deleteMemory = async (path, record) => {
    const r = FirebaseStorage.getImageNameFromUrl(path)

    // Delete from Firebase Realtime DB
    await DB.deleteImage(DB.tables.users, currentUser, record.id, 'memories')
      .then(() => {
        getImages()
      })
      .finally(async () => {
        // Delete from Firebase Storage
        // await FirebaseStorage.delete(FirebaseStorage.directories.memories, currentUser.id, imageName)
      })
  }

  const addImageAnimation = async () => {
    document.querySelectorAll('.img-container').forEach((memoryImage, i) => {
      setTimeout(() => {
        memoryImage.classList.add('active')
      }, 200 * i)
    })
  }

  // Image Navigation
  useEffect(() => {
    const memoryContainers = document.querySelectorAll('.memory-container')
    memoryContainers.forEach((container) => container.classList.remove('active'))
    if (memoryContainers && memoryContainers.length > 0) {
      if (popupImageIndex < 0) {
        setPopupImageIndex(0)
      }
      memoryContainers[popupImageIndex < memoryContainers.length && popupImageIndex > 0 ? popupImageIndex : 0].classList.add('active')
    }
  }, [popupImageIndex])

  useEffect(() => {
    getImages().then((r) => r)
    Manager.toggleForModalOrNewForm()
    setState({ ...state, showMenuButton: true, showBackButton: false })
  }, [])

  return (
    <>
      <PopupCard title={''} className={showImagePopup ? 'active has-image multiple' : ''} onClose={() => setShowImagePopup(false)}>
        {Manager.variableIsValid(imgArray, true) &&
          imgArray.map((memory, index) => {
            return (
              <div {...handlers} key={index} className={index === 0 ? 'active memory-container' : 'memory-container'}>
                <img src={memory.url} />
              </div>
            )
          })}
        {popupImageIndex > 0 && (
          <span id="left-arrow" className="material-icons-round" onClick={() => setPopupImageIndex((popupImageIndex) => popupImageIndex - 1)}>
            arrow_back_ios
          </span>
        )}
        {popupImageIndex < document.querySelectorAll('.memory-container').length && (
          <span
            id="right-arrow"
            className="material-icons-round"
            onClick={() => {
              if (popupImageIndex < document.querySelectorAll('.memory-container').length) {
                setPopupImageIndex((popupImageIndex) => popupImageIndex + 1)
              }
            }}>
            arrow_forward_ios
          </span>
        )}
      </PopupCard>
      <p className="screen-title ">Memories</p>
      <AddNewButton icon="add_photo_alternate" onClick={() => setState({ ...state, currentScreen: ScreenNames.newMemory })} />
      <div id="memories-container" className="page-container">
        <p id="happy-subtitle" className="mb-10">
          Upload photos of memories that are too good NOT to share <span className="material-icons heart">favorite</span>
        </p>

        {memories && memories.length === 0 && <p className="instructions center">There are currently no memories</p>}
        {memories && memories.length > 0 && <p className="gallery instructions">Tap image to expand</p>}

        {/* GALLERY */}
        <div className={`gallery active`}>
          {memories &&
            memories.length > 0 &&
            memories.map((imgObj, index) => {
              return (
                <div className="img-container mb-30" id="img-container" key={index}>
                  <div
                    data-id={imgObj.id}
                    className="mb-10"
                    onClick={(e) => expandImage(e)}
                    id="img-content-container"
                    style={{ backgroundImage: `url(${imgObj.url})` }}></div>
                  <div className="flex">
                    {imgObj.notes && imgObj.notes.length > 0 && (
                      <p className={imgObj.notes.length > 100 ? 'notes fade-bottom' : 'notes'}>{imgObj.notes}</p>
                    )}
                    {imgObj.canDelete && (
                      <p onClick={() => deleteMemory(imgObj.url, imgObj)} className="button  default w-30">
                        DELETE
                      </p>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </>
  )
}
