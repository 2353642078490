const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "coparenting-app-aa9f9.firebaseapp.com",
  databaseURL: "https://coparenting-app-aa9f9-default-rtdb.firebaseio.com",
  projectId: "coparenting-app-aa9f9",
  storageBucket: "coparenting-app-aa9f9.appspot.com",
  messagingSenderId: "7278132726",
  appId: "1:7278132726:web:e6d4b345a6969022991bd7",
};

// Initialize Firebase
export default firebaseConfig;
