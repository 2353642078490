import ScreenNames from "./screenNames"

export default AllMenuItems =
  [
        "Name": ScreenNames.coparentingSpace,
        "IconName": "meeting_room",
        "ScreenName": ScreenNames.coparentingSpace,
        "AccessType": "parent"

          "Name": ScreenNames.calendar,
          "IconName": "calendar_month",
          "ScreenName": ScreenNames.calendar,
          "AccessType": "parent"

        "Name": ScreenNames.chats,
        "IconName": "question_answer",
        "ScreenName": ScreenNames.chats,
        "AccessType": "parent"

          "Name": ScreenNames.expenseTracker,
          "IconName": "paid",
          "ScreenName": ScreenNames.expenseTracker,
          "AccessType": "parent"

      "Name": ScreenNames.swapRequests,
      "IconName": "swap_horizontal_circle",
      "ScreenName": ScreenNames.swapRequests,
      "AccessType": "parent"

          "Name": ScreenNames.transferRequests,
          "IconName": "update",
          "ScreenName": ScreenNames.transferRequests,
          "AccessType": "parent"

            "Name": ScreenNames.documents,
            "IconName": "description",
            "ScreenName": ScreenNames.documents,
            "AccessType": "parent"

          "Name": ScreenNames.memories,
          "IconName": "collections",
          "ScreenName": ScreenNames.memories,
          "AccessType": "parent"

            "Name": ScreenNames.childInfo,
            "IconName": "face",
            "ScreenName": ScreenNames.childInfo,
            "AccessType": "parent"

          "Name": ScreenNames.coparents,
          "IconName": "family_restroom",
          "ScreenName": ScreenNames.coparents,
          "AccessType": "parent"

            "Name": ScreenNames.account,
            "IconName": "manage_accounts",
            "ScreenName": ScreenNames.account,
            "AccessType": "parent"

          "Name": ScreenNames.settings,
          "IconName": "settings",
          "ScreenName": ScreenNames.settings,
          "AccessType": "parent"

  ]