import React, { useContext, useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import Autocomplete from 'react-google-autocomplete'
import globalState from '../../../context'
import DB from '@db'
import Manager from '@manager'
import ScreenNames from '@screenNames'
import { useSwipeable } from 'react-swipeable'
import SignaturePad from 'signature_pad'
import ChatManager from 'managers/chatManager'
import FirebaseStorage from '@firebaseStorage'
import moment from 'moment'
import CheckboxGroup from 'components/shared/checkboxGroup'

function ChatRecovery() {
  const { state, setState } = useContext(globalState)
  const { currentUser, theme, setTheme } = state
  const [reason, setReason] = useState('')
  const [coparentPhone, setCoparentPhone] = useState('')
  const [signaturePad, setSignaturePad] = useState(null)
  const [viewConvo, setViewConvo] = useState(false)
  const [convoText, setConvoText] = useState('')
  const [canvasHeight, setCanvasHeight] = useState(0)
  const [signatureUrl, setSignatureUrl] = useState('')
  const [messageType, setMessageType] = useState('all')
  const [ctx, setCtx] = useState(null)

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      setState({ ...state, currentScreen: ScreenNames.account })
    },
  })

  const submit = async () => {
    if (reason.length === 0) {
      setState({ ...state, alertMessage: 'Please provide a reason for recovering this deleted conversation', showAlert: true })
      return false
    }
    if (signaturePad && signaturePad.isEmpty()) {
      setState({ ...state, alertMessage: 'Please draw your signature', showAlert: true })
      return false
    }
    if (coparentPhone.length === 0) {
      setState({ ...state, alertMessage: 'Please enter the phone number of the coparent you were chatting with', showAlert: true })
      return false
    }
    setViewConvo(true)
    await fillConvoCanvas()
  }

  const fillConvoCanvas = async () => {
    setState({ ...state, isLoading: true })
    setViewConvo(true)
    const canvas = document.querySelector('.conversation-canvas')
    const ctx = canvas.getContext('2d')
    var signatureImage = new Image()
    signatureImage.id = 'pic'
    signatureImage.src = canvas.toDataURL()
    let all = []
    let scopedChat
    let deletedChats = await DB.getTable(DB.tables.archivedChats)

    deletedChats.forEach((chat) => {
      const memberPhones = chat.members.map((x) => x.phone)
      if (memberPhones.includes(currentUser.phone) && memberPhones.includes(coparentPhone)) {
        scopedChat = chat
      }
    })

    // Error if no scoped chat
    if (!Manager.variableIsValid(scopedChat) || scopedChat?.messages.length === 0) {
      setState({
        ...state,
        alertMessage: 'We could not find an archived chat with the details provided.',
        showAlert: true,
        isLoading: false,
      })
      setViewConvo(false)
      Manager.showPageContainer()

      return false
    } else {
      let messages = scopedChat.messages
      if (messageType === 'Bookmarked') {
        // deletedChats = deletedChats.filter(x => x.)
        messages = messages.filter((x) => x.saved === true)
      }

      function fillTextMultiLine(ctx, text, x, y) {
        var lines = text.split('\n')
        for (var i = 0; i < lines.length; ++i) {
          ctx.fillText(lines[i], x, y)
          y += 20
        }
      }

      messages.forEach((messageObj, index) => {
        if (index >= messages.length - 1) {
          all.push(`END OF CONVERSATION\n`)
        } else {
          all.push(`From: ${messageObj.sender}\n  ${messageObj.timestamp.replace(' ', ' at ')}\n   Message: ${messageObj.message}\n`)
        }
        let messagesToUse = all.join(',').replaceAll(',', '\n')
        setConvoText(messagesToUse)
        setCtx(ctx)
        canvas.style.backgroundColor = '#121735'

        ctx.fillStyle = 'white'
        ctx.font = '18px Arial'
        fillTextMultiLine(ctx, messagesToUse, 20, 30)
        const canvasContainer = document.querySelector('#canvas-wrapper')
        const canvasContainerHeight = canvasContainer.offsetHeight
        const heightOfMessages = messages.length * 65
        const maxScrollDistance = heightOfMessages - canvasContainerHeight
        canvasContainer.style.overflowX = 'hidden'

        setCanvasHeight(maxScrollDistance - 200)

        // On Scroll
        canvasContainer.addEventListener('scroll', function (e) {
          const scrollDistance = e.target.scrollTop
          if (scrollDistance > maxScrollDistance - 200) {
            e.preventDefault()
            canvasContainer.style.overflowY = 'hidden'
            setTimeout(() => {
              canvasContainer.scrollBy(0, -200)
              canvasContainer.style.overflowY = 'scroll'
            }, 500)
          }
        })
      })
      setState({ ...state, isLoading: false })
      document.querySelector('.conversation-container').style.display = 'block'

      const signaturePadImage = FirebaseStorage.base64ToImage(signaturePad.toDataURL(), 'signature.jpg')
      await FirebaseStorage.uploadChatRecoverySignature(scopedChat.id, signaturePadImage).finally(async (img) => {})
      await FirebaseStorage.getSingleImage(FirebaseStorage.directories.chatRecoveryRequests, scopedChat.id, 'signature.jpg').then(async (url) => {
        if (url && url !== undefined && url.length > 0) {
          const chatRecoveryRequest = {
            id: scopedChat.id,
            members: scopedChat.members,
            requestTimestamp: moment().format('MM/DD/yyyy hh:mma'),
            firebaseStorageUrl: url,
          }

          // console.log(img)
          await DB.add(DB.tables.chatRecoveryRequests, chatRecoveryRequest)
        }
      })
    }
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(convoText).then(
      function () {
        setState({ ...state, alertMessage: 'Conversation copied!', showAlert: true, alertType: 'success' })
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }

  const saveAsImage = () => {
    let convoCanvas = document.querySelector('.conversation-canvas')
    async function downloadImage(imageSrc) {
      const image = await fetch(imageSrc)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)

      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image file name here'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }

    let w = convoCanvas.width
    let h = convoCanvas.height
    let pix = { x: [], y: [] }
    let imageData = ctx.getImageData(0, 0, convoCanvas.width, convoCanvas.height)
    let x
    let y
    let index

    for (y = 0; y < h; y++) {
      for (x = 0; x < w; x++) {
        index = (y * w + x) * 4
        if (imageData.data[index + 3] > 0) {
          pix.x.push(x)
          pix.y.push(y)
        }
      }
    }
    pix.x.sort(function (a, b) {
      return a - b
    })
    pix.y.sort(function (a, b) {
      return a - b
    })
    var n = pix.x.length - 1

    w = 1 + pix.x[n] - pix.x[0]
    h = 1 + pix.y[n] - pix.y[0]
    var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h)

    convoCanvas.width = w
    convoCanvas.height = h
    ctx.putImageData(cut, 0, 0)

    var image = convoCanvas.toDataURL()
    downloadImage(image)
    setTimeout(() => {
      setState({ ...state, currentScreen: ScreenNames.account })
    }, 1000)
  }

  const handleMessageTypeSelection = async (e) => {
    Manager.handleCheckboxSelection(
      e,
      () => {},
      (e) => {
        setMessageType(e)
      },
      false
    )
  }

  useEffect(() => {
    Manager.toggleForModalOrNewForm()
    setState({ ...state, goBackScreen: ScreenNames.account, showBackButton: true, showMenuButton: false })
    const signaturePadElement = document.querySelector('.signature-pad')
    if (signaturePadElement) {
      const sigPad = new SignaturePad(signaturePadElement, {
        backgroundColor: '#2b356388',
      })
      setSignaturePad(sigPad)
    }
    document.querySelector('.conversation-container').style.display = 'none'
  }, [])

  return (
    <>
      {!viewConvo && (
        <>
          <p className="screen-title ">Chat Recovery</p>
          <div {...handlers} id="chat-request-container" className="page-container">
            <div className="form">
              <label>
                Phone Number of Conversation Coparent <span className="asterisk">*</span>
              </label>
              <input onChange={(e) => setCoparentPhone(e.target.value)} type="number" pattern="[0-9]*" inputMode="numeric" className="mb-15" />
              <textarea
                onChange={(e) => setReason(e.target.value)}
                className="mb-20"
                placeholder="Please provide a reason for recovering this deleted conversation"></textarea>
              <p className="blue">Which types of messages would you like to see/download?</p>
              <CheckboxGroup onCheck={handleMessageTypeSelection} labels={['Bookmarked Only', 'All']} />
              <p className="mb-10 white-text">
                Please provide your signature below to provide self-identification, in the event that the messages are used for legal purposes.
              </p>
              <p className="mb-20 white-text">
                <i>
                  I, {currentUser.name}, agree and understand that by signing the Electronic Signature Acknowledgment and Consent Form, that all
                  electronic signatures are the legal equivalent of my manual/handwritten signature and I consent to be legally bound to this
                  agreement.
                </i>
              </p>
              {/* SIGNATURE PAD */}
              <canvas className="signature-pad mb-20"></canvas>
              <button className="button default center mb-10 w-60" onClick={() => signaturePad.clear()}>
                Reset Signature Pad
              </button>

              <button className="button default green center w-60" onClick={submit}>
                View Messages
                <span className="material-icons-outlined">visibility</span>
              </button>
            </div>
          </div>
        </>
      )}
      <div className="conversation-container">
        <p className="screen-title ">Conversation</p>
        <div {...handlers} id="chat-request-container" className="page-container active">
          <p className="center block mb-5">
            Your conversation below is scrollable. If you tap the Copy button below, the entire conversation will be stored in your clipboard.
          </p>
          <p className="mb-10 center block">
            You can paste it into any document editing program (<b>DESKTOP COMPUTER ONLY</b>) you choose (notepad, Microsoft Word, etc.).
          </p>
          <div className="flex mb-10">
            <button className="default button center mr-10 w-50" onClick={copyToClipboard}>
              Copy Convo
            </button>
            <button className="button default w-50" onClick={saveAsImage}>
              Download Image
            </button>
          </div>
          <div id="canvas-wrapper">
            <canvas width="400" height="10000" className="conversation-canvas"></canvas>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatRecovery
