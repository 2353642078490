import React, { useState, useEffect, useContext } from 'react'

export default function Loading({ isLoading }) {
  const [show, setShow] = useState(isLoading)

  useEffect(() => {
    setTimeout(() => {
      setShow(false)
    }, 5000)
    if (isLoading === false) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [isLoading])

  return (
    <div className={show === true ? 'loading-overlay active' : 'loading-overlay'}>
      <img src={require('../../img/loading.gif')} alt="Loading" />
    </div>
  )
}
