import React, { useContext } from 'react'
import globalState from '../../context'
import '../../prototypes'
import Manager from '@manager'
import { useSwipeable } from 'react-swipeable'

export default function BottomCard({ onClose, children, title, showCard = false, className = '' }) {
  const { state, setState } = useContext(globalState)

  const isMobile = window.screen.width < 800

  const handlers = useSwipeable({
    onSwipedDown: (eventData) => {
      console.log('User Swiped!', eventData)
      onClose()
    },
    preventScrollOnSwipe: true,
  })

  return (
    <div {...handlers} id="bottom-card" className={showCard === true ? `active ${className}` : className}>
      {className.contains('error') && (
        <p id="title">
          Error <span className="material-icons-round">notification_important</span>
        </p>
      )}
      {!className.contains('error') && <p id="title">{title}</p>}
      {children}
      {isMobile && <p id="swipe-instructions">swipe down to close</p>}
      {!isMobile && (
        <span
          className="material-icons-round"
          id="close-icon"
          onClick={() => {
            if (onClose) {
              onClose()
            }
            Manager.toggleForModalOrNewForm('show')
          }}>
          expand_more
        </span>
      )}
    </div>
  )
}
