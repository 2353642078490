import Manager from '@manager'

class Expense {
  constructor(
    _id = '',
    _name = '',
    _imageName = '',
    _shareWith = [],
    _amount = '',
    _createdBy = '',
    _dateAdded = Manager.getCurrentDate(),
    _paidStatus = 'unpaid',
    _children = [],
    _dueDate,
    _phone,
    _notes,
    _recipientName,
    _repeating = false
  ) {
    this.repeating = _repeating
    this.recipientName = _recipientName
    this.name = _name
    this.amount = _amount
    this.paidStatus = _paidStatus
    this.children = _children
    this.dueDate = _dueDate
    this.notes = _notes
    this.shareWith = _shareWith
    this.id = _id
    this.phone = _phone
    this.dateAdded = _dateAdded
    this.createdBy = _createdBy
    this.imageName = _imageName
  }
}

export default Expense
