import Manager from '@manager'

class SwapRequest {
  constructor(
    _id = '',
    _fromDate = '',
    _shareWith = [],
    _createdBy = '',
    _toDate = '',
    _dateAdded = '',
    _recipientPhone = '',
    _reason = '',
    _duration = '',
    _phone = '',
    _children = '',
    _fromHour = '',
    _toHour = '',
    _range = []
  ) {
    this.range = _range
    this.children = []
    this.id = Manager.getUid()
    this.fromDate = _fromDate
    this.toDate = _toDate
    this.dateAdded = Manager.getCurrentDate()
    this.recipientPhone = _recipientPhone
    this.shareWith = _shareWith
    this.reason = _reason
    this.duration = _duration
    this.phone = _phone
    this.createdBy = _createdBy
    this.fromHour = _fromHour
    this.toHour = _toHour
  }
}

export default SwapRequest
