import React, { useState, useEffect, useContext, useRef } from 'react'
import Modal from '@shared/modal'
import globalState from '../context'
import Manager from 'managers/manager'

export default function PaymentOptionsModal({ showModal, onClose }) {
  const { state, setState } = useContext(globalState)

  useEffect(() => {
    Manager.toggleForModalOrNewForm('hide')
  }, [showModal])

  return (
    <Modal elClass={`payment-options-modal ${showModal ? 'active' : ''}`} onClose={onClose}>
      <div className="header">Payment/Transfer Options</div>
      <p className="subtitle">
        There are a multitude of simple and FREE ways to send money to a coparent for expenses, or for any other reason. Please look below to see
        which option works best for you.
      </p>
      <div className="options">
        {/* ZELLE */}
        <div className="option zelle">
          <div className="flex">
            <img className="active" src={require('../img/brandLogos/zelle.png')} alt="" />
            <div className="text">
              <p className="brand-name">Zelle</p>
              <p className="description mb-5">Safely send money to coparent, no matter where they bank.</p>
              <a href="https://www.zellepay.com/how-it-works" target="_blank" className="setup-instructions mb-10">
                Learn More <span className="material-icons">open_in_new</span>
              </a>
            </div>
          </div>
          <iframe
            src="https://www.youtube.com/embed/OTZcPfLlq4w"
            title="Zelle® | How it Works"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>
        </div>

        {/* VENMO */}
        <div className="option venmo">
          <div className="flex">
            <img className="active" src={require('../img/brandLogos/venmo.png')} alt="" />
            <div className="text">
              <p className="brand-name">Venmo</p>
              <p className="description mb-5">Fast, safe, social payments.</p>
              <a
                href="https://help.venmo.com/hc/en-us/articles/209690068-How-to-Sign-Up-for-a-Personal-Venmo-Account"
                target="_blank"
                className="setup-instructions mb-10">
                Learn More <span className="material-icons">open_in_new</span>
              </a>
            </div>
          </div>
          <iframe
            src="https://www.youtube.com/embed/zAqz0Kzootg"
            title="Paying or Requesting Payment From Multiple Users in a Single Transaction"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen></iframe>
        </div>
        {/* APPLE PAY */}
        <div className="option apple-cash">
          <div className="flex ">
            <img className="active" src={require('../img/brandLogos/applepay.png')} alt="" />
            <div className="text">
              <p className="brand-name">Apple Cash</p>
              <p className="description mb-5">Use Apple Cash to send and receive money with people you know.</p>
              <a href="https://support.apple.com/en-us/105013" target="_blank" className="setup-instructions mb-10">
                Learn More <span className="material-icons">open_in_new</span>
              </a>
            </div>
          </div>
        </div>

        {/* PAYPAL */}
        <div className="option paypal">
          <div className="flex">
            <img className="active" src={require('../img/brandLogos/paypal.png')} alt="" />
            <div className="text">
              <p className="brand-name">PayPal</p>
              <p className="description mb-5">Send and request money, quickly and securely.</p>
              <a href="https://www.paypal.com/us/digital-wallet/send-receive-money" target="_blank" className="setup-instructions mb-10">
                Learn More <span className="material-icons">open_in_new</span>
              </a>
            </div>
          </div>
        </div>

        {/* CASHAPP */}
        <div className="option cashapp">
          <div className="flex">
            <img className="active" src={require('../img/brandLogos/cashapp.png')} alt="" />
            <div className="text">
              <p className="brand-name">CashApp</p>
              <p className="description mb-5">Pay anyone, instantly.</p>
              <a href="https://cash.app/help/6485-getting-started-with-cash-app" target="_blank" className="setup-instructions mb-10">
                Learn More <span className="material-icons">open_in_new</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
