import Manager from "./manager"

export default AppManager =
  setAppBadge: (count) =>
    if window.navigator.setAppBadge
      window.navigator.setAppBadge(count)
  clearAppBadge: =>
#    window.navigator.clearAppBadge()
  isDevMode: =>
    location.hostname == 'localhost'
  getAccountType: (currentUser) =>
    if Manager.variableIsValid(currentUser)
      if Manager.variableIsValid(currentUser.accountType)
        if currentUser.accountType == 'parent'
          return 'parent'
        else
          return 'child'
      'parent'
  hidePopupCard: () =>
    cardContainer = document.getElementById("popup-card-container")
    if cardContainer
      cardContainer.classList.remove("active")
  applyVersionNumberToUrl: () =>
    versionNumber = Manager.getUid().substring(0,4)
    formattedUpdateUrl = window.location.href.replaceAll(versionNumber, '')
    formattedUpdateUrlWithOneVersion = formattedUpdateUrl.substring(0, formattedUpdateUrl.indexOf("/") + versionNumber)
    history.replaceState(versionNumber, '', formattedUpdateUrlWithOneVersion)

