
export default ThemeManager =
  colors:
    bgDark: 'black'
    bgLight: 'white'
    textDark: '#141a32'
    textLight: 'rgba(157,173,253,0.98)'
    screenTitleDark: '#254e7e'
    screenTitleLight: 'whitesmoke'
    complimentaryDark: '#5265b8'
    complimentaryLight: '#6495ED'
    inputButtonLight: '#001F48'
    inputButtonDark: 'whitesmoke'
    inputButtonTextDark: 'whitesmoke'
    inputButtonTextLight: '#001F48'
    yellowLight: '#6495ED'
    yellowDark: '#FFFAA0'
    menuBgDark: 'black'
    menuBgLight: 'whitesmoke'
    modalBgDark: 'rgba(0, 0, 0, 0.7)'
    modalBgLight: 'rgba(0, 0, 0, 0.2)'
    eventDetailsLight: '#F0F0F0'
    eventDetailsDark: '#2b356388'
    translucentDark: 'rgba(255, 255, 255, 0.2)'
    translucentLight: 'rgba(0, 0, 0, 0.1)'
    accentLight: '#6495ED'
    accentDark: '#fc76ae'
    messageToBgDark: '#5e56f7'
    messageToBgLight: '#5e56f7'
    purpleLight: '#6447f5'
    purpleDark: '#6447f5'
    darkPurpleDark: '#151b33'
    textColorWhiteLight: '#212427'
    textColorWhiteDark: 'white'
    borderColorLight: '#B0B0B0'
    borderColorDark: '#2b356388'
    whiteIconLight: '#6495ED'
    whiteIconDark: 'white'
    starBgLight: '#121735'
    starBgDark: '#121735'
    eventLeftBorderLight: '#6495ED'
    eventLeftBorderDark: '#2e3a73'
    pageContainerLight: '#f1f2f6'
    pageContainerDark: '#121735'
  changeVariable: (variable, color) ->
    document.body.style.setProperty(variable, color)
  changeTheme: (themeColor) ->
    if themeColor is 'dark'
      ThemeManager.changeVariable '--bgColor', ThemeManager.colors.bgDark
      ThemeManager.changeVariable '--textColor', ThemeManager.colors.textLight
      ThemeManager.changeVariable '--screenTitle', ThemeManager.colors.screenTitleDark
      ThemeManager.changeVariable '--inputButton', ThemeManager.colors.screenTitleLight
      ThemeManager.changeVariable '--inputButtonText', ThemeManager.colors.inputButtonTextLight
      ThemeManager.changeVariable '--yellow', ThemeManager.colors.yellowDark
      ThemeManager.changeVariable '--compColor', ThemeManager.colors.complimentaryDark
      ThemeManager.changeVariable '--menuBg', ThemeManager.colors.menuBgDark
      ThemeManager.changeVariable '--modalBg', ThemeManager.colors.modalBgDark
      ThemeManager.changeVariable '--eventDetails', ThemeManager.colors.eventDetailsDark
      ThemeManager.changeVariable '--translucent', ThemeManager.colors.translucentDark
      # ThemeManager.changeVariable '--menuItems', ThemeManager.colors.menuItemsDark
      ThemeManager.changeVariable '--accent', ThemeManager.colors.accentDark
      ThemeManager.changeVariable '--messageToBg', ThemeManager.colors.messageToBgDark
      ThemeManager.changeVariable '--purple', ThemeManager.colors.purpleDark
      ThemeManager.changeVariable '--darkPurple', ThemeManager.colors.darkPurpleDark
      ThemeManager.changeVariable '--textColorWhite', ThemeManager.colors.textColorWhiteDark
      ThemeManager.changeVariable '--borderColor', ThemeManager.colors.borderColorDark
      ThemeManager.changeVariable '--whiteIcon', ThemeManager.colors.whiteIconDark
      ThemeManager.changeVariable '--starBg', ThemeManager.colors.starBgDark
      ThemeManager.changeVariable '--eventLeftBorder', ThemeManager.colors.eventLeftBorderDark
      ThemeManager.changeVariable '--pageContainer', ThemeManager.colors.pageContainerDark
    else
      ThemeManager.changeVariable '--translucent', ThemeManager.colors.translucentLight
      ThemeManager.changeVariable '--bgColor', ThemeManager.colors.bgLight
      ThemeManager.changeVariable '--textColor', ThemeManager.colors.textDark
      ThemeManager.changeVariable '--screenTitle', ThemeManager.colors.screenTitleLight
      ThemeManager.changeVariable '--inputButton', ThemeManager.colors.screenTitleDark
      ThemeManager.changeVariable '--inputButtonText', ThemeManager.colors.inputButtonTextDark
      ThemeManager.changeVariable '--yellow', ThemeManager.colors.yellowLight
      ThemeManager.changeVariable '--compColor', ThemeManager.colors.complimentaryLight
      ThemeManager.changeVariable '--menuBg', ThemeManager.colors.menuBgLight
      ThemeManager.changeVariable '--eventDetails', ThemeManager.colors.eventDetailsLight
      # ThemeManager.changeVariable '--menuItems', ThemeManager.colors.menuItemsDark
      ThemeManager.changeVariable '--accent', ThemeManager.colors.accentLight
      ThemeManager.changeVariable '--messageToBg', ThemeManager.colors.messageToBgLight
      ThemeManager.changeVariable '--purple', ThemeManager.colors.purpleDark
      ThemeManager.changeVariable '--textColorWhite', ThemeManager.colors.textColorWhiteLight
      ThemeManager.changeVariable '--borderColor', ThemeManager.colors.borderColorLight
      ThemeManager.changeVariable '--whiteIcon', ThemeManager.colors.whiteIconLight
      ThemeManager.changeVariable '--starBg', ThemeManager.colors.starBgLight
      ThemeManager.changeVariable '--eventLeftBorder', ThemeManager.colors.eventLeftBorderLight
      ThemeManager.changeVariable '--pageContainer', ThemeManager.colors.pageContainerLight



