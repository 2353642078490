import PushAlertApi from '@api/pushAlert'
import UploadInputs from '@components/shared/uploadInputs'
import DB from '@db'
import FirebaseStorage from '@firebaseStorage'
import AppManager from '@managers/appManager'
import CheckboxGroup from '@shared/checkboxGroup'
import MyConfetti from '@shared/myConfetti'
import ScreenNames from 'constants/screenNames'
import globalState from 'context'
import Manager from 'managers/manager'
import NotificationManager from 'managers/notificationManager'
import React, { useContext, useEffect, useRef, useState } from 'react'
import BottomButton from '../shared/bottomButton'

function NewMemoryForm() {
  const { state, setState } = useContext(globalState)
  const { currentUser } = state
  const [shareWith, setShareWith] = useState([])
  const [memoryNotes, setMemoryNotes] = useState('')
  const inputFile = useRef(null)

  const handleShareWithSelection = async (e) => {
    await Manager.handleShareWithSelection(e, currentUser, shareWith).then((updated) => {
      setShareWith(updated)
    })
  }

  const submit = async () => {
    setState({ ...state, isLoading: true })
    const imgs = document.querySelector('#upload-input').files
    if (imgs.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Please choose an image', isLoading: false })
      return false
    }
    const notAnImage = Object.entries(imgs).some((x) => {
      return x[1].name.includes('.doc')
    })
    if (notAnImage) {
      setState({ ...state, isLoading: false, showAlert: true, alertMessage: 'Files uploaded MUST be images (.png, .jpg, .jpeg, etc.).' })
      return false
    }

    await FirebaseStorage.uploadMultiple(`${FirebaseStorage.directories.memories}/`, currentUser.id, imgs)
      .then(() => {
        const checkedCheckbox = document.querySelector('.share-with-container .box.active')
        if (checkedCheckbox) {
          checkedCheckbox.classList.remove('active')
        }
      })
      .finally(async () => {
        // Add memories to 'memories' property for currentUser
        await FirebaseStorage.getUrlsFromFiles(FirebaseStorage.directories.memories, currentUser.id, imgs).then(async (urls) => {
          // Add to user memories object

          for (const url of urls) {
            await DB.addToUserMemories(currentUser, 'memories', {
              notes: memoryNotes,
              id: Manager.getUid(),
              url: url,
              shareWith: shareWith,
            }).finally(() => {
              setState({ ...state, isLoading: false, currentScreen: ScreenNames.memories })
            })
          }

          // Send Notification
          for (const coparentPhone of shareWith) {
            const subId = await NotificationManager.getUserSubId(coparentPhone)
            PushAlertApi.sendMessage(`Memories Await!`, `${currentUser.name.formatNameFirstNameOnly()} has uploaded a new memory!`, subId)
          }
        })
        MyConfetti.fire()
        AppManager.setAppBadge(1)
      })
  }

  useEffect(() => {
    Manager.toggleForModalOrNewForm()
    setState({ ...state, goBackScreen: ScreenNames.memories, showMenuButton: false, showBackButton: true })
  }, [])

  return (
    <>
      <p className="screen-title ">New Memory</p>
      <div id="new-memory-form-container" className="page-container">
        <div className="form">
          {currentUser && (
            <div className="share-with-container mb-20">
              <label>
                <span className="material-icons-round">visibility</span>Who should see it?<span className="asterisk">*</span>
              </label>
              <CheckboxGroup
                dataPhone={currentUser.coparents.map((x) => x.phone)}
                labels={currentUser.coparents.map((x) => x.name)}
                onCheck={handleShareWithSelection}
              />
            </div>
          )}
          <textarea className="mb-15" placeholder="Image Description/Notes" onChange={(e) => setMemoryNotes(e.target.value)}></textarea>
          <UploadInputs uploadButtonText="Choose Image" upload={submit} />
        </div>
        <BottomButton onClick={submit} />
      </div>
    </>
  )
}

export default NewMemoryForm
