import Manager from '@manager'

class Schooling {
  constructor(schoolName = '', grade = '') {
    ;(this.schoolName = ''), (this.grade = '')
  }

  // Id
  set setSchoolName(schoolName) {
    this.schoolName = schoolName
  }
  get getSchoolName() {
    return this.schoolName
  }

  // Address
  set setGrade(grade) {
    this.grade = grade
  }
  get getGrade() {
    return this.grade
  }
}

export default Schooling
