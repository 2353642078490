import { child, getDatabase, ref, set } from 'firebase/database'
import React, { useContext, useEffect, useState } from 'react'
import Autocomplete from 'react-google-autocomplete'
import ScreenNames from '@screenNames'
import globalState from '../../../context'
import Coparent from '../../../models/coparent'
import Manager from '@manager'
import CheckboxGroup from '@shared/checkboxGroup'
import BottomButton from 'components/shared/bottomButton'

const NewCoparentForm = () => {
  const { state, setState } = useContext(globalState)
  const { currentUser } = state

  // State
  const [name, setName] = useState(null)
  const [address, setAddress] = useState(null)
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [parentType, setParentType] = useState(null)

  const submit = async () => {
    const dbRef = ref(getDatabase())
    if (Manager.validation([phoneNumber, address, parentType, name]) > 0) {
      setState({ ...state, showAlert: true, alertMessage: 'All fields are required' })
    } else {
      // setState({ ...state, currentScreen: ScreenNames.myProfile, alertMessage: '', showAlert: false })
      const existingCoparents = currentUser.coparents
      const newCoparent = new Coparent()
      newCoparent.id = Manager.getUid()
      newCoparent.address = address !== null ? address : ''
      newCoparent.phone = phoneNumber
      newCoparent.name = name
      newCoparent.parentType = parentType

      // Has children already
      if (existingCoparents.length > 0) {
        set(child(dbRef, `users/${currentUser.phone}/coparents`), [...existingCoparents, newCoparent])
      }
      // Add new child
      else {
        set(child(dbRef, `users/${currentUser.phone}/coparents`), [newCoparent])
      }
    }
  }

  const handleCoparentType = (e) => {
    Manager.handleCheckboxSelection(
      e,
      () => {},
      (e) => {
        setParentType(e)
      }
    )
  }

  useEffect(() => {
    Manager.toggleForModalOrNewForm('hide')
    setState({ ...state, goBackScreen: ScreenNames.coparents })
  }, [])

  return (
    <>
      <p className="screen-title ">Add Coparent</p>
      <div id="new-coparent-container" className="page-container">
        <div className="form new-coparent-form">
          <input className="mb-15" type="text" placeholder="Name*" onChange={(e) => setName(e.target.value)} />
          <input className="mb-15" type="tel" placeholder="Phone number*" onChange={(e) => setPhoneNumber(e.target.value)} />
          <Autocomplete
            className="mb-15"
            placeholder="Enter address*"
            apiKey={process.env.REACT_APP_AUTOCOMPLETE_ADDRESS_API_KEY}
            options={{
              types: ['geocode', 'establishment'],
              componentRestrictions: { country: 'usa' },
            }}
            onPlaceSelected={(place) => {
              setAddress(place.formatted_address)
            }}
          />
          <CheckboxGroup className="coparent-type" labels={['Step-Parent', 'Biological Parent', "Spouse's Coparent"]} onCheck={handleCoparentType} />
          <BottomButton text="Add Coparent" onClick={submit} elClass={''} />
        </div>
      </div>
    </>
  )
}

export default NewCoparentForm
