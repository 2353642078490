import React, { useContext, useEffect, useState } from 'react'
import ScreenNames from '@screenNames'
import globalState from '../../../context'
import DB from '@db'
import FirebaseStorage from '../../../database/firebaseStorage'
import TableOfContentsListItem from '../../legalDocToc'
import Modal from '@shared/modal'
import DocManager from '@managers/docManager'
import ImageManager from '@managers/imageManager'
import Manager from '@manager'
import { useSwipeable } from 'react-swipeable'

export default function NonLegalDocuments({ doc }) {
  const { state, setState } = useContext(globalState)
  const { currentUser, goBackScreen, currentScreen } = state
  const [images, setImages] = useState([])
  const [loadedImages, setLoadedImages] = useState(1)
  const [imageCount, setImageCount] = useState(0)
  const [isDoneLoading, setIsDoneLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [tocHeaders, setTocHeaders] = useState([])

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      console.log('User Swiped!', eventData)
      setState({ ...state, currentScreen: ScreenNames.calendar })
    },
  })

  const scrollToHeader = (header) => {
    const el = document.querySelector(`[data-header-name='${header.replaceAll(',', '-')}']`)
    console.log(el)
    el.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const getDocsAndImages = async () => {
    if (currentUser) {
      setIsDoneLoading(true)
      const url = doc.url
      const fileName = FirebaseStorage.getImageNameFromUrl(url)
      const textContainer = document.getElementById('text-container')
      const urlParams = new URLSearchParams(url)
      const token = urlParams.get('token')

      fetch(`${DocManager.nodeApiUrl()}getDocText?url=${url}&bucketName=${currentUser.id}&token=${token}`)
        .then((response) => response.json())
        .then((data) => {
          textContainer.innerHTML = data.text
          const pars = textContainer.querySelectorAll('p')
          pars.forEach((par, index) => {
            if (par.textContent.stringHasNumbers() && par.textContent.toLowerCase().indexOf('article') > -1) {
              par.classList.add('header')
            }
            if (par.textContent.isAllUppercase() && !par.classList.contains('header')) {
              par.classList.add('highlight')
            }
            const allStrongs = par.querySelectorAll('strong')
            allStrongs.forEach((thisStrong) => {
              DocManager.addHeaderClass(thisStrong)
            })
            DocManager.addHeaderClass(par)
          })

          // Cleanup unecessary header classes
          pars.forEach((par) => {
            if (par.textContent.wordCount() > 10) {
              par.classList.remove('header')
            }
            if (par.textContent.indexOf('___') > -1) {
              par.classList.remove('header')
            }
            if (par.classList.contains('header')) {
              const header = par.textContent.replace(/ /g, '-').replace(/[0-9]/g, '').replace('.', '').replace(/\s/g, '')
              par.setAttribute('data-header-name', header)
            }
          })

          // Set TOC headers
          const headers = document.querySelectorAll('.header')
          let newHeaderArray = []
          headers.forEach((header) => {
            const text = header.textContent
              .replaceAll(' ', '-')
              .replaceAll('-', ' ')
              .replace(/ /g, '-')
              .replace(/[0-9]/g, '')
              .replace('.', '')
              .replace(/\s/g, '')
            if (newHeaderArray.indexOf(text) === -1) {
              newHeaderArray.push(text)
            }
          })
          setTocHeaders(newHeaderArray.sort())
        })
    }
  }

  const navigateToImage = (direction) => {
    const img = document.querySelector('#modal-img')
    const src = img.getAttribute('src')
    let imgIndex
    let imageUrls = images.map((x) => x.url)
    imgIndex = imageUrls.findIndex((x) => x === src)

    if (imgIndex > -1 && imgIndex + 1 < images.length) {
      if (direction === 'forward') {
        img.src = images[imgIndex + 1].url
      } else {
        if (images[imgIndex - 1] === undefined) {
          img.src = images[images.length - 1].url
        } else {
          img.src = images[imgIndex - 1].url
        }
      }
    } else {
      if (images[0] !== undefined) {
        img.src = images[0].url
      }
    }
  }

  const toggleToc = () => {
    const menu = document.getElementById('menu-container')
    const backArrow = document.querySelector('.back-arrow')
    const toc = document.querySelector('#table-of-contents')
    const tocIcon = document.getElementById('toc-icon')
    const overlay = document.querySelector('.page-overlay')
    const closeTocIcon = document.getElementById('toc-close-icon')
    const screenTitle = document.querySelector('.screen-title')
    if (toc.classList.contains('open')) {
      toc.classList.remove('open')
      menu.style.display = 'flex'
      backArrow.style.display = 'flex'
      tocIcon.style.display = 'flex'
      overlay.classList.remove('active')
      closeTocIcon.classList.remove('active')
      screenTitle.style.display = 'flex'
    } else {
      screenTitle.style.display = 'none'
      toc.classList.add('open')
      closeTocIcon.classList.add('active')
      menu.style.display = 'none'
      tocIcon.style.display = 'none'
      overlay.classList.add('active')
      backArrow.style.display = 'none'
    }
  }

  const expandImage = (e) => {
    setShowModal(true)
    const modal = document.querySelector('.image-modal')
    modal.classList.add('show')
    ImageManager.expandImage(e, modal)
  }

  const deleteDoc = async (path, record) => {
    const imageName = await FirebaseStorage.getImageNameFromUrl(path)

    // Delete from Firebase Realtime DB
    await DB.deleteImage(DB.tables.users, currentUser, record.id, 'documents')
      .then(() => {
        getDocsAndImages()
      })
      .finally(async () => {
        // Delete from Firebase Storage
        await FirebaseStorage.delete(FirebaseStorage.directories.documents, currentUser.id, imageName)
      })
  }

  useEffect(() => {
    if (imageCount > 0 && loadedImages === imageCount) {
      document.querySelectorAll('.agreement-image').forEach((img) => {
        if (img.complete) {
          setIsDoneLoading(true)
        }
      })
    }
  }, [loadedImages])

  useEffect(() => {
    setState({ ...state, goBackScreen: ScreenNames.documents })
    Manager.toggleForModalOrNewForm('show')
    getDocsAndImages()
  }, [])

  return (
    <>
      <p className="screen-title ">Non-Legal Documents</p>
      <Modal
        hasClose={true}
        hasNavigation={imageCount > 1 ? true : false}
        onNavigateBack={() => navigateToImage('back')}
        onNavigateForward={() => navigateToImage('forward')}
        elClass={'image-modal non-legal-documents'}
        onClose={(e) => {
          document.querySelector('#menu-container').style.display = 'block'
          setShowModal(false)
          document.querySelector('.image-modal').classList.remove('show')
        }}>
        <img src="" id="modal-img" />
      </Modal>
      <div id="documents-container" {...handlers}>
        <div className="page-overlay"></div>
        <span id="toc-icon" className="material-icons" onClick={toggleToc}>
          toc
        </span>
        <button onClick={toggleToc} id="toc-close-icon" className="button red center">
          close
        </button>
        {!showModal && (
          <div id="table-of-contents">
            <div id="toc-contents">
              {tocHeaders.length > 0 &&
                tocHeaders.sort().map((header, index) => {
                  return (
                    <span key={index}>
                      <TableOfContentsListItem
                        agreementText={document.querySelector('#text-container').textContent}
                        text={header}
                        onClick={() => {
                          toggleToc()
                          scrollToHeader(header.replace(/ /g, '-').replace(/[0-9]/g, '').replace('.', '').replace(/\s/g, ''))
                        }}
                      />
                    </span>
                  )
                })}
            </div>
          </div>
        )}
        {!showModal && images.length > 0 && imageCount && imageCount > 0 && (
          <>
            <p className="gallery instructions">Click image to expand</p>
            <div className={`gallery ${isDoneLoading ? 'active' : ''}`}>
              {imageCount && imageCount > 0 && (
                <img className={isDoneLoading === true ? '' : 'active'} src={require('../../../img/loading.gif')} id="loading-gif" />
              )}

              {images.length > 0 &&
                images.map((imgObj, index) => {
                  return (
                    <div id="img-container" key={index}>
                      <img
                        data-url={imgObj.url}
                        onLoad={(e) => {
                          setLoadedImages((loadedImages) => loadedImages + 1)
                        }}
                        onClick={(e) => expandImage(e)}
                        src={imgObj.url}
                        onError={(e) => setImages(images.filter((x) => x.id !== imgObj.id))}
                        className="agreement-image"
                      />
                      {imgObj.name && imgObj.name.length > 0 && <p className="image-name">{imgObj.name}</p>}
                      <p onClick={() => deleteDoc(imgObj.url, imgObj)}>DELETE</p>
                    </div>
                  )
                })}
            </div>
          </>
        )}
        <div id="text-container"></div>
      </div>
    </>
  )
}
