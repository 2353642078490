import React, { useEffect, useLayoutEffect, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { initializeApp } from 'firebase/app'
import { child, get, getDatabase, onValue, ref, set } from 'firebase/database'
import { CustomProvider } from 'rsuite'
import Menu from '@components/menu.jsx'
import EventCalendar from '@screens/calendar.jsx'
import ScreenNames from '@screenNames'
import globalState from './context.js'
import DB from '@db'
import firebaseConfig from './firebaseConfig.js'
import Manager from '@manager'
import moment from 'moment'
// Screens
import InstallAppPopup from '@components/installAppPopup.jsx'
import Account from '@screens/account/account.jsx'
import UpdateContactInfo from '@screens/account/updateContactInfo.jsx'
import Chat from '@screens/chats/chats.jsx'
import Conversation from '@screens/chats/conversation.jsx'
import ChildInfo from '@screens/childInfo/childInfo.jsx'
import Coparents from '@screens/coparents/coparents.jsx'
import DocsList from '@screens/documents/docsList.jsx'
import Documents from '@screens/documents/documents.jsx'
import LegalDocs from '@screens/documents/legalDocs.jsx'
import NonLegalDocuments from '@screens/documents/nonLegalDocuments.jsx'
import UploadDocuments from '@screens/documents/uploadDocuments.jsx'
import UploadLegalDoc from '@screens/documents/uploadLegalDoc.jsx'
import ExpenseTracker from '@screens/expenseTracker.jsx'
import ForgotPassword from '@screens/account/forgotPassword.jsx'
import Login from '@screens/auth/login.jsx'
import Memories from '@screens/memories.jsx'
import Registration from '@screens/auth/registration.jsx'
import ContactSupport from '@screens/settings/contactSupport.jsx'
import CoparentingSpace from '@screens/coparentingSpace.jsx'
import FeatureRequest from '@screens/settings/featureRequest.jsx'
import Feedback from '@screens/settings/feedback.jsx'
import Settings from '@screens/settings/settings.jsx'
import SwapRequests from '@screens/swapRequests.jsx'
import TransferRequests from '@screens/transferRequests.jsx'
import Alert from '@shared/alert.jsx'
import AppManager from '@managers/appManager.js'
import ThemeManager from '@managers/themeManager.js'
import ChatRecovery from '@screens/account/chatRecovery'
import BubbleMenu from '@components/shortcutMenu.jsx'
import DateManager from '@managers/dateManager.js'
import NewCalendarEvent from '@components/forms/newCalendarEvent.jsx'
import EditCalEvent from '@components/screens/editCalEvent.jsx'
import NewChildForm from 'components/screens/childInfo/newChildForm.jsx'
import NewMemoryForm from 'components/forms/newMemoryForm.jsx'
import NewExpenseForm from 'components/forms/newExpenseForm.jsx'
import NewSwapRequest from 'components/forms/newSwapRequest.jsx'
import NewChildTransferChangeRequest from 'components/forms/newChildTransferChange.jsx'
import emailjs from '@emailjs/browser'
import NewCoparentForm from 'components/screens/coparents/newCoparentForm.jsx'
import CalendarManager from 'managers/calendarManager.js'
import Loading from 'components/shared/loading.jsx'
import ChildSelector from 'components/screens/childInfo/childSelector.jsx'
import DB_UserScoped from '@userScoped'

const stateObj = {
  alertMessage: '',
  alertType: 'error',
  confirmMessage: '',
  contactInfoToUpdateType: 'email',
  currentScreen: ScreenNames.login,
  currentUser: {},
  calEventToEdit: {},
  goBackScreen: '',
  isLoading: true,
  menuIsOpen: false,
  messageToUser: null,
  modalIsOpen: false,
  selectedChild: null,
  showAlert: false,
  showConfirm: false,
  showMenuButton: true,
  showShortcutMenu: false,
  showBackButton: false,
  showOverlay: false,
  theme: 'dark',
  unreadMessages: null,
  unreadMessagesCountSet: false,
  userIsLoggedIn: false,
  users: [],
  viewExpenseForm: false,
  viewSwapRequestForm: false,
  viewTransferRequestForm: false,
  setAlertMessage: (alertMessage) => {},
  setShowBackButton: (bool) => {},
  setAlertType: (type) => {},
  setConfirmMessage: (message) => {},
  setContactInfoToUpdateType: () => {},
  setCurrentScreen: (screen) => {},
  setCurrentUser: (user) => {},
  setDateToEdit: (date) => {},
  setEventToEdit: (event) => {},
  setGoBackScreen: (screen) => {},
  setIsLoading: (bool) => {},
  setMenuIsOpen: (isOpen) => {},
  setMessageToUser: (user) => {},
  setModalIsOpen: (bool) => {},
  setSelectedChild: (child) => {},
  setShowAlert: (bool) => {},
  setShowShortcutMenu: (bool) => {},
  setShowMenuButton: () => {},
  setShowOverlay: (bool) => {},
  setTheme: (theme) => {},
  setUnreadMessages: (count) => {},
  setUnreadMessagesCountSet: (bool) => {},
  setUserIsLoggedIn: (isLoggedIn) => {},
  setUsers: (users) => {},
  setViewExpenseForm: (show) => {},
  setViewSwapRequestForm: (show) => {},
  setViewTransferRequestForm: (show) => {},
}

export default function App() {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig)
  const [state, setState] = useState(stateObj)
  const stateToUpdate = { state, setState }
  const [showOverlay, setShowOverlay] = useState(false)

  const myCanvas = document.createElement('canvas')
  document.body.appendChild(myCanvas)

  emailjs.init({
    publicKey: 'khikD1NoIHmBPlckL',
    // Do not allow headless browsers
    blockHeadless: true,
    blockList: {
      // Block the suspended emails
      list: [],
      // The variable contains the email address
      // watchVariable: 'userEmail',
    },
    limitRate: {
      // Set the limit rate for the application
      id: 'app',
      // Allow 1 request per 10s
      throttle: 5000,
    },
  })

  // State to include in App.js
  const { showMenuButton, showShortcutMenu, isLoading, goBackScreen, calEventToEdit, currentScreen, menuIsOpen, showBackButton, currentUser } = state

  const setCurrentUser = async (stateUpdates) =>
    new Promise(async (resolve, reject) => {
      const rememberKey = localStorage.getItem('rememberKey')
      await DB.getTable(DB.tables.users)
        .then(async (people) => {
          people = DB.convertKeyObjectToArray(people)
          if (Manager.variableIsValid(people, true)) {
            const user = people.filter((x) => x.id === rememberKey)[0]
            ThemeManager.changeTheme(user?.settings?.theme || 'dark')
            // const unreadCount = await getUnreadMessageCount(user)
            if (user) {
              setState({
                ...state,
                theme: user.theme,
                currentScreen: ScreenNames.calendar,
                currentUser: user,
                userIsLoggedIn: true,
                isLoading: false,
                ...stateUpdates,
              })
              // Update app
              if (!AppManager.isDevMode()) {
                console.log(true)
                if (!user.updateButtonPressed) {
                  await DB_UserScoped.updateUserRecord(user.phone, `updateButtonPressed`, true)
                  window.location.reload()
                } else {
                  setTimeout(async () => {
                    await DB_UserScoped.updateUserRecord(user.phone, `updateButtonPressed`, false)
                  }, 1000)
                }
              }
              // TODO check if pushAlertSubs has this user already
              subscribeUser(user)
            } else {
              resolve('')
              ThemeManager.changeTheme('dark')
            }
          } else {
            resolve('')
            setState({ ...state, isLoading: false })
            ThemeManager.changeTheme('dark')
          }
        })
        .finally(async () => {
          resolve('')
        })
    })

  const subscribeUser = (user) => {
    ;(pushalertbyiw = window.pushalertbyiw || []).push(['addToSegment', 38837, onSubscribe])

    async function onSubscribe(result) {
      let pushalertbyiw = []
      if (result.success) {
        ;(pushalertbyiw = window.pushalertbyiw || []).push(['onReady', onPushAlertReady])
      } else {
        ;(pushalertbyiw = window.pushalertbyiw || []).push(['subscribeToSegment', 38837])
      }
    }

    async function onPushAlertReady() {
      console.log('onPushAlertReady')
      const dbRef = ref(getDatabase())

      DB.getTable(DB.tables.pushAlertSubscribers).then((users) => {
        const subscribers = Object.entries(users)
        subscribers.forEach((sub) => {
          const phone = sub[0]
          const id = sub[1]
          if (currentUser && phone === currentUser.phone) {
            return false
          } else {
            const subId = PushAlertCo.subs_id
            set(child(dbRef, `pushAlertSubscribers/${user.phone}/`), subId)
          }
        })
      })
    }
  }

  const getUnreadMessageCount = async (thisUser) => {
    const dbRef = ref(getDatabase())
    let unreadMessages = []

    await get(child(dbRef, `${DB.tables.users}`)).then((users) => {
      const usersVal = users.val()
      const usersWithChats = DB.convertKeyObjectToArray(usersVal).filter((x) => x.chats !== undefined)
      usersWithChats.forEach((userWithChats, index) => {
        const threads = DB.convertKeyObjectToArray(userWithChats.chats).flat()
        let scopedChats = threads.map((x) => x.messages).flat()
        scopedChats.forEach((message) => {
          if (Manager.variableIsValid(message, true)) {
            if (message.readState === 'delivered' && message.fromName !== currentUser.name) {
              unreadMessages.push(message)
            }
          }
        })
      })
    })
    const unreadMessageCount = unreadMessages.flat().length
    if (navigator.setAppBadge) {
      if (unreadMessageCount > 0) {
        AppManager.setAppBadge()
      } else {
        AppManager.clearAppBadge()
      }
    }
    return unreadMessageCount
  }

  const deleteExpiredCalendarEvents = async () => {
    let events = await DB.getTable(DB.tables.calendarEvents)
    if (!Array.isArray(events)) {
      events = DB.convertKeyObjectToArray(events)
    }
    if (Manager.variableIsValid(events, true)) {
      events = events.map((x) => x !== undefined)
      for (const event of events) {
        if (Manager.variableIsValid(event)) {
          const daysPassed = DateManager.getDuration('days', moment(), event.fromDate)
          if (daysPassed <= -30 && event.isHoliday === false) {
            await DB.delete(DB.tables.calendarEvents, event.id)
          }
        }
      }
    }
  }

  const showScreenTitleShadowOnScroll = () => {
    const pageContainer = document.querySelector('.page-container')
    if (pageContainer) {
      pageContainer.addEventListener('scroll', () => {
        const distanceToTop = pageContainer.scrollTop
        const screenTitle = document.querySelector('.screen-title')
        if (distanceToTop > 50) {
          screenTitle.classList.add('shadow')
        } else {
          screenTitle.classList.remove('shadow')
        }
      })
    }
  }

  const setHolidays = async () => {
    const cal = await DB.getTable(DB.tables.calendarEvents)
    const holidays = cal.filter((x) => x.isHoliday === true)
    if (holidays.length === 0) {
      await DateManager.setHolidays()
    }
  }

  // SHOW SCREEN TITLE / HIDE CALENDAR ON SCREEN CHANGE
  useEffect(() => {
    if (currentScreen !== ScreenNames.calendar) {
      CalendarManager.hideCalendar()
    }
    // Manager.toggleForModalOrNewForm('show')
    showScreenTitleShadowOnScroll()
  }, [currentScreen])

  // ON PAGE LOAD
  useEffect(() => {
    // navigator.clearAppBadge().then((r) => r)
    // Reset users for phone num
    // const dbRef = ref(getDatabase())
    // const users = DB.convertKeyObjectToArray(JSON.parse(backup))
    // users.forEach((user) => {
    //   set(child(dbRef, `users/${user.phone}`), user)
    // })

    setState({ ...state, isLoading: true })

    deleteExpiredCalendarEvents().then((r) => r)
    setCurrentUser().then((r) => r)
    ThemeManager.changeTheme('dark')
    // throw new Error('Something went wrong')
    // setHolidays()
    // CalendarManager.deDupe()
  }, [])

  return (
    <CustomProvider theme="dark">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className="App" id="app-container">
          {/* LOADING */}
          <Loading isLoading={isLoading} />

          {/* UPDATE POPUP */}
          <div className={showOverlay ? 'active overlay' : 'overlay'}>
            <div className="update-popup">
              <p className="title">
                Update Available <span className="material-icons">update</span>
              </p>
              <p className="message">Close out of the app and open it again for an instant update!</p>
            </div>
          </div>

          {/* BACK BUTTON */}
          {showBackButton && (
            <div id="previous-screen-button-container">
              <button
                onClick={() => {
                  if (currentScreen === ScreenNames.newCalendarEvent) {
                    setState({
                      ...state,
                      menuIsOpen: false,
                      currentScreen: ScreenNames.chats,
                    })
                    setTimeout(() => {
                      setState({
                        ...state,
                        menuIsOpen: false,
                        currentScreen: ScreenNames.calendar,
                        showBackButton: false,
                        showMenuButton: true,
                      })
                    }, 100)
                  } else {
                    setState({
                      ...state,
                      currentScreen: goBackScreen,
                      showMenuButton: true,
                      showBackButton: false,
                    })
                    Manager.toggleForModalOrNewForm('show')
                  }
                }}
                className="previous-screen-button">
                <span className="material-icons-round">arrow_circle_left</span>
              </button>
            </div>
          )}

          {/* INSTALL APP MODAL */}
          <InstallAppPopup />
          <globalState.Provider value={stateToUpdate}>
            <Alert />

            {/* SCREENS */}
            <div>
              {currentScreen === ScreenNames.editCalendarEvent && Manager.variableIsValid(calEventToEdit) && <EditCalEvent />}
              {currentScreen === ScreenNames.updateContactInfo && <UpdateContactInfo />}

              {/* UPLOAD */}
              {currentScreen === ScreenNames.uploadAgreement && <UploadLegalDoc />}
              {currentScreen === ScreenNames.uploadDocuments && <UploadDocuments />}

              {/* NEW */}
              {currentScreen === ScreenNames.newCalendarEvent && <NewCalendarEvent />}
              {currentScreen === ScreenNames.newMemory && <NewMemoryForm />}
              {currentScreen === ScreenNames.newChild && <NewChildForm />}
              {currentScreen === ScreenNames.newExpense && <NewExpenseForm />}
              {currentScreen === ScreenNames.newSwapRequest && <NewSwapRequest />}
              {currentScreen === ScreenNames.newTransferRequest && <NewChildTransferChangeRequest />}
              {currentScreen === ScreenNames.newCoparent && <NewCoparentForm />}

              {/* STANDARD */}
              {currentScreen === ScreenNames.calendar && <EventCalendar />}
              {currentScreen === ScreenNames.settings && <Settings />}
              {currentScreen === ScreenNames.account && <Account />}
              {currentScreen === ScreenNames.contactSupport && <ContactSupport />}
              {currentScreen === ScreenNames.login && <Login />}
              {currentScreen === ScreenNames.registration && <Registration />}
              {currentScreen === ScreenNames.expenseTracker && <ExpenseTracker />}
              {currentScreen === ScreenNames.swapRequests && <SwapRequests />}
              {currentScreen === ScreenNames.forgotPassword && <ForgotPassword />}
              {currentScreen === ScreenNames.legalDocs && <LegalDocs />}
              {currentScreen === ScreenNames.documents && <Documents />}
              {currentScreen === ScreenNames.transferRequests && <TransferRequests />}
              {currentScreen === ScreenNames.memories && <Memories />}
              {currentScreen === ScreenNames.childInfo && <ChildInfo />}
              {currentScreen === ScreenNames.coparents && <Coparents />}
              {currentScreen === ScreenNames.conversation && <Conversation />}
              {currentScreen === ScreenNames.nonLegalDocuments && <NonLegalDocuments />}
              {currentScreen === ScreenNames.chats && <Chat />}
              {currentScreen === ScreenNames.featureRequests && <FeatureRequest />}
              {currentScreen === ScreenNames.feedback && <Feedback />}
              {currentScreen === ScreenNames.docsList && <DocsList />}
              {currentScreen === ScreenNames.coparentingSpace && <CoparentingSpace />}
              {currentScreen === ScreenNames.childSelector && <ChildSelector />}
              {currentScreen === ScreenNames.chatRecovery && <ChatRecovery />}
            </div>

            {/* MENU  */}
            {menuIsOpen && <Menu />}

            {/* SHORTCUT MENU */}
            {showShortcutMenu && <BubbleMenu />}

            {/* MENU BUTTON */}
            {!menuIsOpen && (
              <button
                id="menu-button"
                className={showMenuButton ? 'button bottom visible' : 'button bottom hide'}
                onClick={(e) => setState({ ...state, showShortcutMenu: true })}>
                <span className="material-icons-round">menu</span>
              </button>
            )}
          </globalState.Provider>
        </div>
      </LocalizationProvider>
    </CustomProvider>
  )
}
