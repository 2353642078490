import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import ScreenNames from '@screenNames'
import globalState from '../../../context.js'
import DB from '@db'
import Manager from '@manager'
import CheckboxGroup from '@shared/checkboxGroup.jsx'
import DB_UserScoped from '@userScoped'
import InstallAppPopup from 'components/installAppPopup.jsx'
export default function Login() {
  const { state, setState } = useContext(globalState)

  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [rememberMe, setRememberMe] = useState(false)
  const [viewPassword, setViewPassword] = useState(false)

  const login = async () => {
    if (Manager.validation([phone, password]) > 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Please fill out all fields' })
      return false
    }

    await DB.getTable(DB.tables.users).then((data) => {
      data = Object.entries(data)
      let foundUser = null

      data.filter((x) => {
        x.forEach(async (user) => {
          if (user && user.phone && user.password) {
            if (user.phone === phone && user.password === password) {
              foundUser = user
              if (rememberMe) {
                localStorage.setItem('rememberKey', user.id)
                await DB_UserScoped.updateUserRecord(user.phone, 'rememberMe', true)
              }
            }
          }
        })
      })[0]

      if (foundUser) {
        setState({
          ...state,
          userIsLoggedIn: true,
          currentScreen: ScreenNames.calendar,
          currentUser: foundUser,
          isLoading: false,
          showMenuButton: true,
        })
        Manager.toggleForModalOrNewForm('show')
      } else {
        Manager.toggleForModalOrNewForm('show')
        setState({ ...state, currentScreen: ScreenNames.login, showAlert: true, alertMessage: 'Incorrect phone and/or password', isLoading: false })
      }
    })
  }

  const toggleRememberMe = (e) => {
    const clickedEl = e.currentTarget
    const checkbox = clickedEl.querySelector('.box')
    if (checkbox.classList.contains('active')) {
      setRememberMe(false)
      checkbox.classList.remove('active')
    } else {
      checkbox.classList.add('active')
      setRememberMe(true)
    }
  }

  useLayoutEffect(() => {
    setState({ ...state, currentScreen: ScreenNames.login, showMenuButton: false })
    setTimeout(() => {
      Manager.toggleForModalOrNewForm('show')
    }, 1000)
  }, [])

  return (
    <>
      {/* INSTALL APP MODAL */}
      <InstallAppPopup />
      <p className="screen-title  show center-text mt-20 pt-20 mb-10 w-100 p-0">Login</p>
      <div id="login-container" className="page-container">
        <p
          id="install-button"
          className="mb-10 button mt-10"
          onClick={() => {
            setState({ ...state, menuIsOpen: false })
            document.querySelector('.install-app').classList.add('active')
            Manager.toggleForModalOrNewForm('hide')
          }}>
          Install App <span className="material-icons">install_mobile</span>
        </p>
        <div id="quote-container">
          <span>
            <code>❝</code>
          </span>
          <p id="quote">
            Co-parenting. It's not a competition between two homes. It's <b>a collaboration of parents doing what is best for the kids</b>
          </p>
          <p id="author">~ Heather Hetchler</p>
        </div>
        <div className="flex form-container">
          <div className="form w-80">
            <input
              className="mb-15"
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Phone number"
              onChange={(e) => setPhone(e.target.value)}
            />
            <div className="flex inputs mb-20">
              <input type={viewPassword ? 'text' : 'password'} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
              {!viewPassword && (
                <span className="material-icons-round" onClick={() => setViewPassword(true)}>
                  visibility
                </span>
              )}
              {viewPassword && (
                <span className="material-icons-round" onClick={() => setViewPassword(false)}>
                  visibility_off
                </span>
              )}
            </div>
            <CheckboxGroup boxWidth={50} onCheck={toggleRememberMe} labels={['Remember Me']} skipNameFormatting={true} />
            <div className="flex w-100 mb-15">
              <button className="button default green w-60 mr-10" onClick={login}>
                Login <span className="material-icons-round">lock_open</span>
              </button>
              <button className="button default w-60 " onClick={() => setState({ ...state, currentScreen: ScreenNames.registration })}>
                Register <span className="material-icons-round">person_add</span>
              </button>
            </div>
          </div>

          <p id="forgot-password-link" onClick={() => setState({ ...state, currentScreen: ScreenNames.forgotPassword })}>
            Forgot Password
          </p>
        </div>
      </div>
    </>
  )
}
