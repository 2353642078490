import Manager from '@manager'
import { child, get, getDatabase, push, ref, remove, set, update } from 'firebase/database'
import FirebaseStorage from './firebaseStorage'
import DB_UserScoped from '@userScoped'

const DB = {
  tables: {
    expenseTracker: 'expenseTracker',
    swapRequests: 'swapRequests',
    users: 'users',
    calendarEvents: 'calendarEvents',
    transferChange: 'transferChange',
    inbox: 'inbox',
    pushAlertSubscribers: 'pushAlertSubscribers',
    profilePics: 'profilePics',
    chats: 'chats',
    documents: 'documents',
    archivedChats: 'archivedChats',
    chatRecoveryRequests: 'chatRecoveryRequests',
  },
  runQuery: async (table, query) => {
    const records = await DB.getTable(table)
    return records.filter(query)
  },
  recordExists: async (tableName, record, identifier) =>
    await new Promise(async (resolve) => {
      const dbRef = ref(getDatabase())
      await get(child(dbRef, tableName)).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.val().forEach((shot) => {
            if (shot[identifier] === record[identifier]) {
              resolve(true)
            }
          })
        }
      })
    }),
  convertKeyObjectToArray: (keyObject) => {
    // console.log(keyObject)
    if (Manager.variableIsValid(keyObject)) {
      return Object.entries(keyObject).map((x) => x[1])
    } else {
      return []
    }
  },
  convertFirebaseObjectToObject: (firebaseObject) => {
    return Object.entries(firebaseObject).flat()[1]
  },
  getFilteredRecords: async (records, currentUser) => {
    let returnRecords = []
    if (records && records !== undefined && records.length > 0) {
      // Handle child records
      if (Manager.variableIsValid(currentUser.accountType) && currentUser.accountType === 'child') {
        records.forEach((record, index) => {
          // Filter by phone
          record.shareWith.push(currentUser.phone)
          const mergedPhoneArrays = Manager.getUniqueArray(record.shareWith).flat()
          if (mergedPhoneArrays.includes(currentUser.phone) || record.phone === currentUser.phone) {
            returnRecords.push(record)
          }
        })
      }
      // Handle parent records
      else {
        const coparentPhones = currentUser.coparents.map((x) => x.phone)
        records.forEach((record, index) => {
          // Filter by phone
          const mergedPhoneArrays = Manager.getUniqueArray(coparentPhones.concat(record.shareWith)).flat()
          if (mergedPhoneArrays.includes(currentUser.phone) || record.phone === currentUser.phone) {
            returnRecords.push(record)
          }
        })
      }
    }
    return returnRecords
  },

  getAllFilteredRecords: async (tableName, currentUser, objectName) => {
    let currentUserRecords = undefined
    let coparentRecords = undefined
    const getCoparentRecords = new Promise(async (resolve, reject) => {
      const coparentPhones = currentUser.coparents.map((x) => x.phone)
      coparentPhones.forEach(async (coparentPhone) => {
        DB_UserScoped.getCoparent(coparentPhone).then(async (coparentObj) => {
          DB_UserScoped.getRecordsByUser(tableName, coparentObj, objectName)
            .then((coparentRecord) => {
              if (!Array.isArray(coparentRecord)) {
                coparentRecord = DB.convertKeyObjectToArray(coparentRecord).filter((x) => x.shareWith.includes(currentUser.phone))
              }
              if (coparentRecord !== undefined) {
                // console.log(coparentRecord);
                resolve(coparentRecord)
              } else {
                resolve([])
              }
            })
            .catch((error) => {
              //
            })
        })
      })
    })
    const getUserRecords = new Promise((resolve, reject) => {
      DB_UserScoped.getRecordsByUser(tableName, currentUser, objectName)
        .then((currentUserRecord) => {
          if (!Array.isArray(currentUserRecord)) {
            currentUserRecord = DB.convertKeyObjectToArray(currentUserRecord)
            currentUserRecord = currentUserRecord.map((x) => {
              x.canDelete = true
              return x
            })
          }
          if (currentUserRecord !== undefined) {
            resolve(currentUserRecord)
          } else {
            resolve([])
          }
        })
        .catch((error) => {
          //
        })
    })

    await getUserRecords
      .then((data) => {
        currentUserRecords = data
      })
      .then(async () => {
        getCoparentRecords.then((coparentData) => {
          coparentRecords = coparentData
        })
      })
    const mergedRecords = [...(coparentRecords ?? []), ...(currentUserRecords ?? [])]
    return mergedRecords
  },
  getSnapshotKey: async (tableName, objectToCheck, propertyToCompare) =>
    await new Promise(async (resolve) => {
      const dbRef = ref(getDatabase())
      await get(child(dbRef, tableName)).then((snapshot) => {
        if (snapshot.exists()) {
          snapshot.forEach((event) => {
            if (event.val()[propertyToCompare] == objectToCheck[propertyToCompare]) {
              resolve(event.key)
            }
          })
        }
      })
    }),
  getNestedSnapshotKey: async (recordPath, objectToCheck, propertyToCompare) =>
    await new Promise(async (resolve) => {
      const dbRef = ref(getDatabase())
      // await get(child(dbRef, recordPath)).then((snapshot) => {
      //   snapshot.val().forEach((obj) => {
      //     if (obj[propertyToCompare] === '4199615795') {
      //       console.log(snapshot.key)
      //       resolve(data.key)
      //     }
      //   })
      // })
      await DB.getTable(recordPath).then((data) => {
        if (Array.isArray(data)) {
          for (let prop in data) {
            if (data[prop][propertyToCompare] === objectToCheck[propertyToCompare]) {
              // console.log(prop)
              resolve(prop)
            }
          }
          data.forEach((obj) => {
            if (obj[propertyToCompare] === objectToCheck[propertyToCompare]) {
              resolve(data.key)
            }
          })
        } else {
          for (let prop in data) {
            if (data[prop][propertyToCompare] === objectToCheck[propertyToCompare]) {
              resolve(prop)
            }
          }
        }
      })
    }),

  add: async (tableName, data) =>
    await new Promise(async (resolve) => {
      const dbRef = ref(getDatabase())
      let tableData = []
      tableData = await DB.getTable(tableName)
      // tableData is NOT null
      if (Manager.variableIsValid(tableData)) {
        // NOT an array
        if (!Array.isArray(tableData)) {
          tableData = DB.convertKeyObjectToArray(tableData)
          if (tableData.length > 0) {
            tableData = [...tableData, data].filter((item) => item)
          } else {
            tableData = [data]
          }
        } else {
          // IS array
          if (tableData.length > 0) {
            tableData = [...tableData, data].filter((item) => item)
          } else {
            tableData = [data]
            console.log(tableData)
          }
        }
      }
      // tableData is NULL
      else {
        tableData = [data]
      }
      resolve('')
      console.log(tableData)
      await set(child(dbRef, tableName), tableData)
    }),
  addProfilePic: async (id, imageName) => {
    // console.log(`/profilePics/${id}/${imageName}`)
    const dbRef = ref(getDatabase())
    await set(child(dbRef, `/profilePics/${id}/${imageName}`), tableData)
  },
  addToUserMemories: async (currentUser, objectName, value, id) => {
    const dbRef = ref(getDatabase())
    let tableRecords = await DB.getTable(DB.tables.users)
    tableRecords = DB.convertKeyObjectToArray(tableRecords)
    let toUpdate = tableRecords.filter((x) => x.phone === currentUser.phone)[0]
    if (toUpdate[objectName] !== undefined && toUpdate[objectName].length > 0) {
      toUpdate[objectName] = [...toUpdate[objectName], value]
    } else {
      toUpdate[objectName] = [value]
    }
    if (id) {
      await push(child(dbRef, `/users/${currentUser.phone}/${objectName}/${id}/`), value)
      return toUpdate[objectName]
    } else {
      await push(child(dbRef, `/users/${currentUser.phone}/${objectName}`), value)
      return toUpdate[objectName]
    }
  },
  addProfilePicToChildRecord: async (currentUser, objectName, value, id) => {
    const dbRef = ref(getDatabase())
    let basePath = `${DB.tables.users}/${currentUser.phone}/children`
    await get(child(dbRef, basePath)).then(async (snapshot) => {
      let key = null
      snapshot.forEach((child) => {
        if (child.val().id === id) {
          key = child.key
        }
      })
      basePath = `${basePath}/${key}/profilePic/`
      await FirebaseStorage.getProfilePicUrl(FirebaseStorage.directories.profilePics, id, value).then(async (urls) => {
        set(child(dbRef, basePath), urls)
      })
    })
  },
  delete: async (tableName, id) => {
    const dbRef = ref(getDatabase())
    let idToDelete
    let tableRecords = await DB.getTable(tableName)
    if (Manager.variableIsValid(tableRecords, true)) {
      if (!Array.isArray(tableRecords)) {
        tableRecords = DB.convertKeyObjectToArray(tableRecords)
      }
      tableRecords.forEach(async (record, index) => {
        if (record.id === id) {
          idToDelete = await DB.getSnapshotKey(tableName, record, 'id')
          remove(child(dbRef, `${tableName}/${idToDelete}/`))
        }
      })
    }
  },
  addCalendarEvent: async (data) => {
    const dbRef = ref(getDatabase())
    let currentEvents = await DB.getTable(DB.tables.calendarEvents)
    if (!Array.isArray(currentEvents)) {
      currentEvents = []
    }
    currentEvents = currentEvents.filter((n) => n)
    set(child(dbRef, `${DB.tables.calendarEvents}`), [...currentEvents, data])
  },
  addMultipleCalEvents: async (newEvents) => {
    const dbRef = ref(getDatabase())
    let currentEvents = await DB.getTable(DB.tables.calendarEvents)
    if (!Array.isArray(currentEvents)) {
      currentEvents = []
    }

    // Delete Existing
    currentEvents.forEach((event) => {
      newEvents.forEach(async (newEvent) => {
        if (event.fromDate === newEvent.fromDate && event.title === newEvent.title) {
          await DB.delete(DB.tables.calendarEvents, event.id)
        }
      })
    })
    const eventsToAdd = [...currentEvents, [...newEvents]].filter((x) => x !== undefined).flat()
    set(child(dbRef, `${DB.tables.calendarEvents}`), eventsToAdd).catch((error) => {})
  },
  addVisitationSchedule: async (newEvents) => {
    const dbRef = ref(getDatabase())
    let currentEvents = await DB.getTable(DB.tables.calendarEvents)
    if (!Array.isArray(currentEvents)) {
      currentEvents = []
    }
    // Delete Existing
    currentEvents.forEach((event) => {
      newEvents.forEach(async (newEvent) => {
        if (event.fromDate === newEvent.fromDate && event.title === newEvent.title) {
          await DB.delete(DB.tables.calendarEvents, event.id)
        }
      })
    })
    const eventsToAdd = [...currentEvents, [...newEvents]].filter((x) => x !== undefined).flat()
    set(child(dbRef, `${DB.tables.calendarEvents}`), eventsToAdd)
  },
  deleteImage: async (tableName, currentUser, id, prop) => {
    const dbRef = ref(getDatabase())
    let toDeleteId
    if (prop) {
      let tableRecords = await DB.getTable(tableName)
      tableRecords = DB.convertKeyObjectToArray(tableRecords)
      let userToUpdate = tableRecords.filter((x) => x.phone === currentUser.phone)[0]
      if (userToUpdate === undefined || userToUpdate[prop] === undefined || userToUpdate[prop].length === 0) {
        userToUpdate[prop] = []
      } else {
        const asArray = Object.entries(userToUpdate[prop])
        asArray.forEach((img, index) => {
          if (img[1].id === id) {
            toDeleteId = img[0]
          }
        })
      }
    } else {
      await DB.getTable(tableName).then(async (data) => {
        let tableData = []
        if (data && data.length > 0) {
          tableData = data.filter((x) => {
            return x.id !== id
          })
        }
        await set(child(dbRef, tableName), tableData)
      })
    }
    remove(child(dbRef, `${tableName}/${currentUser.phone}/${prop}/${toDeleteId}/`))
  },

  getTable: async (tableName) => {
    const dbRef = ref(getDatabase())
    let tableData = []
    await get(child(dbRef, tableName)).then((snapshot) => {
      tableData = snapshot.val()
    })
    return tableData
  },

  updateIsAvailable: async (tableName) => {
    const dbRef = ref(getDatabase())
    let available = false
    await get(child(dbRef, '/updateAvailable')).then((snapshot) => {
      available = snapshot.val()
    })
    return available
  },

  updateRecord: async (tableName, recordToUpdate, prop, value, identifier) => {
    const dbRef = ref(getDatabase())
    const tableRecords = DB.convertKeyObjectToArray(await DB.getTable(tableName))
    let toUpdate
    if (identifier && identifier !== undefined) {
      toUpdate = tableRecords.filter((x) => x[identifier] === recordToUpdate[identifier])[0]
    } else {
      toUpdate = tableRecords.filter((x) => x.id === recordToUpdate.id)[0]
    }
    toUpdate[prop] = value
    set(child(dbRef, tableName), tableRecords)
  },
  updateEntireRecord: async (path, newRecord) => {
    const dbRef = getDatabase()
    // ref(path).update(newRecord)
    console.log(newRecord)
    update(ref(dbRef, path), newRecord)
    // update((ref(dbRef, tableName), { newRecord }))
  },
  deleteChildInfoProp: async (tableName, currentUser, prop, parentObjectName, selectedChild) => {
    const dbRef = ref(getDatabase())
    let removalKey
    await get(child(dbRef, `${tableName}/${currentUser.phone}/children/`)).then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((event) => {
          let child = event.val()
          if (child['general'].name === selectedChild['general'].name) {
            removalKey = event.key
          }
        })
      }
      remove(child(dbRef, `${tableName}/${currentUser.phone}/children/${removalKey}/${parentObjectName}/${prop}`))
    })
  },
  deleteCoparentInfoProp: async (tableName, currentUser, prop, selectedCoparent) => {
    const dbRef = ref(getDatabase())
    let removalKey
    await get(child(dbRef, `${tableName}/${currentUser.phone}/coparents/`)).then((snapshot) => {
      if (snapshot.exists()) {
        snapshot.forEach((event) => {
          let coparent = event.val()
          if (coparent.phone === selectedCoparent.phone) {
            removalKey = event.key
          }
        })
      }
      remove(child(dbRef, `${tableName}/${currentUser.phone}/coparents/${removalKey}/${prop}`))
    })
  },

  updatePhoneOrEmail: async (currentUser, prop, valueObject) => {
    const { phone, email } = valueObject
    const dbRef = ref(getDatabase())

    // Update swap requests table
    await DB.getTable(DB.tables.swapRequests).then((data) => {
      if (data && data.length > 0) {
        data.forEach((request, index) => {
          // Update user request
          if (request.shareWith.includes(currentUser.name)) {
            // console.log(request);
            const numberIndex = request.shareWith.indexOf(currentUser.phone)
            request.shareWith[numberIndex] = value
          }
          if (request[prop] === currentUser.phone) {
            request[prop] = value
          }
        })
        // console.log(data);
        // set(child(dbRef, tableName), data)
      }
    })

    // Update expenseTracker table
    await DB.getTable(DB.tables.expenseTracker).then((data) => {
      if (data && data.length > 0) {
        data.forEach((request) => {
          // Update user expenseTracker
          if (request[prop] === currentUser.phone) {
            request[prop] = value
          }
          if (request.shareWith.includes(currentUser.phone)) {
            const numberIndex = request.shareWith.indexOf(currentUser.phone)
            request.shareWith[numberIndex] = value
          }
        })
        // set(child(dbRef, tableName), data);
      }
    })

    // Update cal table
    await DB.getTable(DB.tables.calendarEvents).then((data) => {
      if (data && data.length > 0) {
        data.forEach((event) => {
          // Update user cal
          if (event[prop] === currentUser.phone) {
            event[prop] = value
          }

          if (event.shareWith.includes(currentUser.phone)) {
            const numberIndex = event.shareWith.indexOf(currentUser.phone)
            event.shareWith[numberIndex] = value
          }
        })
        // console.log(data);
        // set(child(dbRef, tableName), data);
      }
    })

    // Update chats table
    await DB.getTable(DB.tables.chats).then(async (data) => {
      const newNumber = '00'
      const database = getDatabase()
      if (data && data.length > 0) {
        data.forEach(async (chat, index) => {
          const recordKey = await DB.getSnapshotKey(`chats/`, chat, 'id')

          // UPDATE firstMessageFrom
          const fmfChat = await DB.getTable(`chats/${recordKey}`)
          const fmfRef = ref(database, `chats/${recordKey}`)
          if (fmfChat.firstMessageFrom === currentUser.phone) {
            fmfChat['firstMessageFrom'] = newNumber
            // await update(fmfRef, fmfChat)
          }

          // UPDATE MEMBER PHONES
          const _members = data[index]['members']
          let memberToUpdateKey = -1
          _members.forEach((thisMember, memberIndex) => {
            if (thisMember.phone === currentUser.phone) {
              memberToUpdateKey = memberIndex
            }
          })
          const updatedMember = await DB.getTable(`chats/${recordKey}/members/${memberToUpdateKey}`)
          const updateMemberPath = ref(database, `chats/${recordKey}/members/${memberToUpdateKey}`)
          updatedMember['phone'] = '99'
          // await update(updateMemberPath, updatedMember)
        })
      }
    })

    // Update users table
    await DB.getTable(DB.tables.users).then((data) => {
      if (data && data.length > 0) {
        data.forEach((record) => {
          // Update user record
          if (record[prop] === currentUser.phone) {
            record[prop] = value
          }

          // Update prop for coparents
          record.coparents.forEach((coparent) => {
            if (coparent[prop] === currentUser.phone) {
              coparent[prop] = value
            }
          })
        })
        // set(child(dbRef, tableName), data);
      }
    })
  },
}

export default DB
