import Manager from '@manager'

class TransferChangeRequest {
  constructor(
    _id = '',
    _date = '',
    _time = '',
    _phone = '',
    _location = '',
    _createdBy = '',
    _directionsLink = '',
    _shareWith = [],
    _reason = '',
    _recipientPhone = '',
    _preferredTransferLocation = ''
  ) {
    this.preferredTransferLocation = _preferredTransferLocation
    this.recipientPhone = _recipientPhone
    this.id = Manager.getUid()
    this.date = _date
    this.phone = _phone
    this.directionsLink = _directionsLink
    this.location = _location
    this.time = _time
    this.createdBy = _createdBy
    this.reason = _reason
    this.shareWith = _shareWith
  }
}

export default TransferChangeRequest
