import Manager from '@manager'
import General from './general'
import Medical from './medical'
import Schooling from './schooling'
import Behavior from './behavior'

class Child {
  constructor(id, general, profilePic, medical, schooling, behavior) {
    this.id = Manager.getUid()
    this.general = new General()
    this.medical = new Medical()
    this.schooling = new Schooling()
    this.behavior = new Behavior()
    this.profilePic = ''
  }
}

export default Child
