const ScreenNames = {
  expenseTracker: 'expenseTracker',
  swapRequests: 'swapRequests',
  login: 'login',
  dashboard: 'dashboard',
  registration: 'registration',
  calendar: 'calendar',
  coparentVerification: 'coparentVerification',
  forgotPassword: 'forgotPassword',
  legalDocs: 'legalDocs',
  uploadAgreement: 'uploadAgreement',
  settings: 'settings',
  account: 'account',
  updateContactInfo: 'updateContactInfo',
  documents: 'documents',
  uploadAgreementDocs: 'uploadAgreementDocs',
  newChildTransferChangeRequest: 'newChildTransferChangeRequest',
  transferRequests: 'transferRequests',
  memories: 'memories',
  childInfo: 'childInfo',
  chats: 'chats',
  conversation: 'conversation',
  coparents: 'coparents',
  uploadDocuments: 'uploadDocuments',
  nonLegalDocuments: 'nonLegalDocuments',
  featureRequests: 'featureRequests',
  feedback: 'feedback',
  contactSupport: 'contactSupport',
  docsList: 'docsList',
  editCalendarEvent: 'editCalendarEvent',
  coparentingSpace: 'coparentingSpace',
  chatRecovery: 'chatRecovery',
  newCalendarEvent: 'newCalendarEvent',
  newChild: 'newChild',
  newMemory: 'newMemory',
  newExpense: 'newExpense',
  newTransferRequest: 'newTransferRequest',
  newCoparent: 'newCoparent',
  childSelector: 'childSelector',
}

export default ScreenNames
