import React, { useState, useEffect, useContext } from 'react'

function BottomButton({ onClick, type = 'submit', text = 'SUBMIT', iconName = 'check', elClass }) {
  return (
    <button className={`${type} ${elClass} button bottom`} onClick={onClick}>
      <span className="material-icons-round">{iconName}</span>
    </button>
  )
}

export default BottomButton
