DateFormats =
  withReadbleDay: 'dddd, MMMM DD, YYYY'
  fullDatetime: 'MM/DD/YYYY h:mma'
  monthAndDay: 'MM/DD'
  dateForDb: 'MM/DD/YYYY'
  jsDateTime: 'yyyy,MM,DD h:mm a'
  timeForDb: 'h:mma'
  monthDayYear: 'MM/DD/YYYY'
  readableDay: 'dddd Do'
  monthOnly: "MM"
  readableMonth: "MMMM"
  flatpickr: "MMMM DD, yyyy"
  summaryHour: "ha"


export default DateFormats