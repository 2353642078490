import Manager from '@manager'

class User {
  constructor(
    _name = 'name',
    _morningReminderSummaryHour = '',
    _id = '',
    _allowNotifications = 'Yes',
    _email = 'email',
    _password = 'password',
    _coparents = 'coparents',
    _children = 'children',
    _phone = 'phone',
    _parentType = 'parentType',
    _accountType = 'parent',
    _eveningReminderSummaryHour = ''
  ) {
    this.name = _name
    this.email = _email
    this.phone = _phone
    this.password = _password
    this.children = _children
    this.coparents = _coparents
    this.parentType = _parentType
    this.allowNotifications = _allowNotifications
    this.parentType = _parentType
    this.id = Manager.getUid()
    this.morningReminderSummaryHour = _morningReminderSummaryHour
    this.eveningReminderSummaryHour = _eveningReminderSummaryHour
  }
}

export default User
