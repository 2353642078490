import Manager from '@manager'

class Medical {
  constructor(doctorName = '', doctorPhone = '', allergy = []) {
    ;(this.doctorName = ''), (this.doctorPhone = ''), (this.allergy = [])
  }

  // Id
  set setDoctorName(doctorName) {
    this.doctorName = doctorName
  }
  get getDoctorName() {
    return this.doctorName
  }

  // Address
  set setDoctorPhone(doctorPhone) {
    this.doctorPhone = doctorPhone
  }
  get getDoctorPhone() {
    return this.doctorPhone
  }

  // Id
  set setAllergies(allergy) {
    this.allergy = allergy
  }
  get getAllergies() {
    return this.allergy
  }
}

export default Medical
