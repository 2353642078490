import { useContext, useEffect, useState } from 'react'

import ScreenNames from '@screenNames'
import DB from '@db'
import Manager from '@manager'
import DB_UserScoped from '@userScoped'
import globalState from '../../../context'
import NonLegalDocuments from './nonLegalDocuments'
import DB_DocumentScoped from '@documentScoped'

export default function DocsList() {
  const { state, setState } = useContext(globalState)
  const { currentUser } = state
  const [docs, setDocs] = useState([])
  const [selectedDoc, setSelectedDoc] = useState(null)

  const getDocs = async () => {
    // Future state for when share with works
    // const allDocs = await DB.getAllFilteredRecords(DB.tables.users, currentUser, 'documents', shareWith)
    const allDocs = await DB_DocumentScoped.getAllDocs()
    setDocs(allDocs)
    console.log(allDocs)
  }

  useEffect(() => {
    getDocs()
    setState({ ...state, goBackScreen: ScreenNames.documents })
    Manager.showPageContainer()
  }, [])

  return (
    <>
      <p className="screen-title ">Non-Legal Documents</p>

      <div id="doc-selection-container" className="page-container">
        {!Manager.variableIsValid(selectedDoc, false, true) && (
          <div className="sections">
            {Manager.variableIsValid(docs, true) &&
              docs.map((doc) => {
                return (
                  <p
                    key={Manager.getUid()}
                    className="section"
                    onClick={() => {
                      setSelectedDoc(doc)
                    }}>
                    {doc.name.formatFileName()}
                  </p>
                )
              })}
          </div>
        )}

        {selectedDoc && <NonLegalDocuments doc={selectedDoc} />}
      </div>
    </>
  )
}
