import Manager from '@manager'

class Behavior {
  constructor(counselorName = '', issues = '', phone = '') {
    ;(this.counselorName = ''), (this.issues = ''), (this.phone = '')
  }

  // Id
  set setCounselorName(counselorName) {
    this.counselorName = counselorName
  }
  get getCounselorName() {
    return this.counselorName
  }

  // Address
  set setMainIssue(issues) {
    this.issues = issues
  }
  get getMainIssue() {
    return this.issues
  }
}

export default Behavior
