import React, { useState } from 'react'

function UploadInputs({ upload, uploadButtonText = 'Choose File' }) {
  const [showUploadButton, setShowUploadButton] = useState(false)
  return (
    <div id="upload-inputs">
      <label onClick={() => setShowUploadButton(true)} htmlFor="upload-input" className="button default custom-file-upload w-50">
        {uploadButtonText}
      </label>
      <input id="upload-input" name="file-upload" type="file" multiple accept="image/*" />
      {showUploadButton && (
        <button id="upload-button" className="button default w-50 green" onClick={upload}>
          Upload <span className="material-icons-round">upload</span>
        </button>
      )}
    </div>
  )
}

export default UploadInputs
