import Manager from '@manager'

class General {
  constructor(dateOfBirth = '', address = '', phone = '', name = '', gender = '') {
    ;(this.dateOfBirth = ''), (this.address = ''), (this.phone = ''), (this.name = ''), (this.gender = gender)
  }

  // Id
  set setDateOfBirth(dateOfBirth) {
    this.dateOfBirth = dateOfBirth
  }
  get getDateOfBirth() {
    return this.dateOfBirth
  }

  // Address
  set setAddress(address) {
    this.address = address
  }
  get getAddress() {
    return this.address
  }

  // Id
  set setPhone(phone) {
    this.phone = phone
  }
  get getPhone() {
    return this.phone
  }

  // Id
  set setName(name) {
    this.name = name
  }
  get getName() {
    return this.name
  }

  // Id
  set setGender(gender) {
    this.gender = gender
  }
  get getGender() {
    return this.gender
  }
}

export default General
