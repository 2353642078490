class CalendarEvent {
  constructor(
    _id = '',
    _websiteUrl = '',
    _notes = '',
    _shareWith = [],
    _toDate = '',
    _fromDate = '',
    _startTime = '',
    _phone = '',
    _children = [],
    _location = '',
    _title = '',
    _createdBy = '',
    _reminderTimes = [],
    _directionsLink = '',
    _endTime = '',
    _morningSummaryReminderSent = false,
    _eveningSummaryReminderSent = false,
    _fromVisitationSchedule = false,
    _repeatInterval = '',
    _sentReminders = [],
    _visitationPeriodEndDate = '',
    _isHoliday = false
  ) {
    this.visitationPeriodEndDate = _visitationPeriodEndDate
    this.isHoliday = _isHoliday
    this.sentReminders = _sentReminders
    this.repeatInterval = _repeatInterval
    this.reminderTimes = _reminderTimes
    this.fromVisitationSchedule = _fromVisitationSchedule
    this.directionsLink = _directionsLink
    this.id = _id
    this.websiteUrl = _websiteUrl
    this.notes = _notes
    this.fromDate = _fromDate
    this.phone = _phone
    this.children = _children
    this.location = _location
    this.title = _title
    this.endTime = _endTime
    this.toDate = _toDate
    this.directionsLink = _directionsLink
    this.shareWith = _shareWith
    this.startTime = _startTime
    this.createdBy = _createdBy
    this.morningSummaryReminderSent = _morningSummaryReminderSent
    this.eveningSummaryReminderSent = _eveningSummaryReminderSent
  }
}
export default CalendarEvent
