class ConversationThread {
  constructor(_id, _members = [], _timestamp = '', _messages = [], _firstMessageFrom = '') {
    this.id = _id
    this.members = _members
    this.timestamp = _timestamp
    this.messages = _messages
    this.firstMessageFrom = _firstMessageFrom
  }
}

export default ConversationThread
