import React, { useState, useEffect, useContext } from 'react'
import ToggleButton from 'react-toggle-button'
import ThemeManager from '@managers/themeManager'
import globalState from '../../../context'
import DB_UserScoped from '@userScoped'
import DB from '@db'
import '@theme-toggles/react/css/Within.css'
import { Within } from '@theme-toggles/react'
export default function ThemeToggle() {
  const { state, setState } = useContext(globalState)
  const { currentUser, currentScreenTitle, theme, setTheme } = state
  const [isToggled, setToggle] = useState(currentUser?.settings?.theme === 'dark' ? false : true)
  const [text, setText] = useState('dark')

  return (
    <div id="theme-toggle">
      <p className={isToggled === true ? 'white' : 'blue'}>{text}</p>
      <Within
        duration={750}
        toggled={isToggled}
        toggle={setToggle}
        onToggle={async (e) => {
          const updatedTheme = e === true ? 'light' : 'dark'
          await DB_UserScoped.updateUserProp(currentUser, `settings`, 'theme', updatedTheme)
          ThemeManager.changeTheme(e === true ? 'light' : 'dark')
          setState({ ...state, theme: e === true ? 'light' : 'dark' })
          setText(e === true ? 'light' : 'dark')
        }}
      />
    </div>
  )
}
