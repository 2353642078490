class Doc {
  constructor_(_name = '', _type = '', _shareWith = [], _url = '', _id = '', _uploadedBy) {
    this.uploadedBy = _uploadedBy
    this.id = _id
    this.name = _name
    this.type = _type
    this.shareWith = _shareWith
    this.url = _url
  }
}

export default Doc
