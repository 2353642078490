import React, { useState, useEffect, useContext, startTransition } from 'react'
import globalState from '../../../context'
import Manager from '@manager'
import ScreenNames from '@screenNames'
import FirebaseStorage from '@firebaseStorage'
import Modal from '@shared/modal'
import DB from '@db'
import SmsManager from '@managers/smsManager'
import CheckboxGroup from '@shared/checkboxGroup'
import Doc from '../../../models/doc'
import DocManager from '@managers/docManager'
import PushAlertApi from '@api/pushAlert'
import NotificationManager from '@managers/notificationManager'

export default function UploadDocuments() {
  const { state, setState } = useContext(globalState)
  const { currentUser } = state
  const [shareWith, setShareWith] = useState([])
  const [isDoneLoading, setIsDoneLoading] = useState(false)
  const [docType, setDocType] = useState(null)

  const upload = async () => {
    setIsDoneLoading(false)
    const files = document.querySelector('#upload-input').files
    if (files.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Please choose a file to upload' })
      return false
    }
    if (!Manager.variableIsValid(shareWith, true) || !Manager.variableIsValid(docType)) {
      setState({ ...state, showAlert: true, alertMessage: 'Document type and Who should see it? are required' })
      return false
    }

    await FirebaseStorage.uploadMultiple(`${FirebaseStorage.directories.documents}/`, currentUser.id, files)
      .then(() => {
        const checkedCheckbox = document.querySelector('.share-with-container .box.active')
        if (checkedCheckbox) {
          checkedCheckbox.classList.remove('active')
        }
      })
      .finally(async () => {
        // Add documents to 'documents' property for currentUser
        await FirebaseStorage.getUrlsFromFiles(FirebaseStorage.directories.documents, currentUser.id, files).then(async (urls) => {
          // Add to user documents object
          urls.forEach(async (url) => {
            const newDocument = new Doc()
            newDocument.url = url
            newDocument.uploadedBy = currentUser.phone
            newDocument.id = Manager.getUid()
            newDocument.shareWith = Manager.getUniqueArray(shareWith).flat()
            newDocument.type = docType
            newDocument.name = FirebaseStorage.getImageNameFromUrl(url).replace('.png', '').replace('.jpg', '').replace('.jpeg', '')
            await DB.add(DB.tables.documents, newDocument).finally(() => {
              setState({ ...state, currentScreen: ScreenNames.documents })
            })
            // const urlParams = new URLSearchParams(url)
            // const token = urlParams.get('token')
            // console.log(token)

            // fetch(`${DocManager.nodeApiUrl()}/fileUpload?url=${url}&bucketName=${currentUser.id}&token=${token}`)
            //   .then((response) => response.json())
            //   .then((data) => {})
          })

          // Send Notification
          NotificationManager.sendToShareWith(shareWith, 'New Document', `${currentUser} has uploaded a new document`)
        })
      })
  }

  const handleShareWithSelection = async (e) => {
    await Manager.handleShareWithSelection(e, currentUser, shareWith).then((updated) => {
      setShareWith(updated)
    })
  }

  const handleCheckboxSelection = (e) => {
    Manager.handleCheckboxSelection(
      e,
      (e) => {
        console.log(e)
      },
      (e) => {
        setDocType(e.toLowerCase())
      },
      false
    )
  }

  useEffect(() => {
    Manager.toggleForModalOrNewForm('show')
    setState({ ...state, goBackScreen: ScreenNames.documents })
  }, [])

  return (
    <>
      <p className="screen-title ">Non-Legal Documents</p>

      <div id="upload-documents-container" className="page-container">
        <p>Upload documents (.pdf, .doc, .docx, etc.) , or images of documents you would like to save or share with a coparent.</p>

        <div className="form">
          <div className="flex mb-15" id="upload-inputs">
            <div id="upload-inputs">
              <label htmlFor="upload-input" className="w-50 button default  custom-file-upload">
                Choose File
              </label>
              <input id="upload-input" type="file" multiple />
              <button className="button w-50 green default" onClick={upload}>
                Upload <span className="material-icons-round">file_upload</span>
              </button>
            </div>
          </div>
          {currentUser && (
            <div className="share-with-container">
              <label>Document type*</label>
              <CheckboxGroup labels={['Legal', 'Non-Legal']} onCheck={handleCheckboxSelection} />
              <label>
                <span className="material-icons-round">visibility</span>Who should see it?*
              </label>
              <CheckboxGroup
                dataPhone={currentUser.coparents.map((x) => x.phone)}
                labels={currentUser.coparents.map((x) => x.name)}
                onCheck={handleShareWithSelection}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}
