import { child, getDatabase, ref, set } from 'firebase/database'
import React, { useContext, useEffect, useState } from 'react'
import PasswordChecklist from 'react-password-checklist'
import ScreenNames from '@screenNames'
import globalState from '../../../context.js'
import Child from '../../../models/child/child.js'
import User from '../../../models/user.js'
import Manager from '@manager'
import ChildrenInput from '../../childrenInput.jsx'
import CoparentInputs from '../../coparentInput.jsx'
import CheckboxGroup from '@shared/checkboxGroup.jsx'
import DB from '@db'
import SmsManager from '@managers/smsManager.js'
import NotificationManager from '@managers/notificationManager.js'
import PushAlertApi from '@api/pushAlert'
import prototypes from '../../../prototypes.js'
import MyConfetti from '@shared/myConfetti.js'
import DB_UserScoped from '@userScoped'
import ChildUser from 'models/child/childUser.js'
import { useSwipeable } from 'react-swipeable'

export default function Registration() {
  const { state, setState } = useContext(globalState)
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmedPassword, setConfirmedPassword] = useState('')
  const [phone, setPhone] = useState('')
  const [children, setChildren] = useState([])
  const [parentType, setParentType] = useState('')
  const [accountType, setAccountType] = useState(null)
  const [parentPhone, setParentPhone] = useState(null)
  const [verificationCode, setVerificationCode] = useState(Manager.getUid().slice(0, 4))
  const [verificationCodeSent, setVerificationCodeSent] = useState(false)
  const [childrenInputs, setChildrenInputs] = useState([
    <ChildrenInput childrenCount={1} onChange={(e) => setChildren([...children, e.target.value])} />,
  ])

  const handlers = useSwipeable({
    onSwipedRight: (eventData) => {
      console.log('User Swiped!', eventData)
      setState({ ...state, currentScreen: ScreenNames.calendar })
    },
  })

  const validateChildForm = () => {
    let valid = true
    if (!parentPhone || parentPhone?.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: "Parent's phone number is required" })
      valid = false
    }
    if (userName.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Your name is required' })
      valid = false
    }
    if (password.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Your password is required' })
      valid = false
    }
    if (confirmedPassword.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Confirmed password is required' })
      valid = false
    }
    if (confirmedPassword !== password) {
      setState({ ...state, showAlert: true, alertMessage: 'Password and confirmed password do not match' })
      valid = false
    }

    return valid
  }

  const validateParentForm = () => {
    console.log('ran')
    let valid = true
    let coparentsArr = []
    let coparentNamesEl = document.querySelectorAll('.coparent-name')
    let coparentPhonesEl = document.querySelectorAll('.coparent-phone')
    let checkboxesEl = document.querySelectorAll('.coparent-input-container .box.active')
    let checkboxValues = Array.from(checkboxesEl).map((x) => x.parentNode.dataset['label'])
    const coparentNames = Array.from(coparentNamesEl).map((x) => x.value)
    const coparentPhones = Array.from(coparentPhonesEl).map((x) => x.value)

    coparentNames.forEach((name, nameIndex) => {
      coparentsArr.push({ name: name })
    })
    coparentPhones.forEach((phone, phoneIndex) => {
      coparentsArr[phoneIndex]['phone'] = phone
    })
    checkboxValues.forEach((checkbox, checkboxIndex) => {
      coparentsArr[checkboxIndex]['parentType'] = checkbox
    })

    const emptyCoparentProps = coparentsArr.filter((x) => x.name === '' || x.phone === '' || x.parentType === '')

    if (emptyCoparentProps.length > 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Empty co-parent fields are not allowed' })
      valid = false
    }

    if (checkboxValues.length === 0) {
      setState({ ...state, showAlert: true, alertMessage: 'Empty co-parent fields/checkboxes are not allowed' })
      valid = false
    }

    if (parentType.length === 0) {
      setState({ ...state, alertMessage: 'Select your parent type', showAlert: true })
      valid = false
    }

    if (Manager.validation([userName, email, phone, parentType]) > 0) {
      setState({ ...state, alertMessage: 'Please fill out all fields', showAlert: true })
      valid = false
    }

    if (!Manager.formatPhoneNumber(phone)) {
      setState({ ...state, alertMessage: 'Enter a valid phone number', showAlert: true })
    }

    if (children.length === 0) {
      setState({ ...state, alertMessage: 'Please enter at least 1 child', showAlert: true })
      valid = false
    }

    if (coparentsArr.length === 0) {
      setState({ ...state, alertMessage: 'Please enter at least 1 coparent', showAlert: true })
      valid = false
    }

    return valid
  }

  const submitChild = async () => {
    const isValid = validateChildForm()
    if (isValid) {
      if (!verificationCode || verificationCode?.length === 0) {
        setState({ ...state, showAlert: true, alertMessage: 'Verification code is required' })
        return false
      }
      let parent = await DB_UserScoped.getUser(DB.tables.users, parentPhone)

      if (Manager.variableIsValid(parent)) {
        let newUser = new ChildUser()
        newUser.id = Manager.getUid()
        newUser.phone = phone
        newUser.name = userName
        newUser.accountType = 'child'
        newUser.password = password
        newUser.email = ''
        const dbRef = ref(getDatabase())
        await set(child(dbRef, `users/${phone}`), newUser)
        MyConfetti.fire()
        setState({ ...state, currentScreen: ScreenNames.login })

        // SEND SMS MESSAGES
        // Send to parent
        const parentSubId = await NotificationManager.getUserSubId(parentPhone)
        PushAlertApi.sendMessage(`${userName} has registered.`, parentSubId)
        // Send to child
        const childSubId = await NotificationManager.getUserSubId(phone)
        PushAlertApi.sendMessage(`${userName} has registered.`, childSubId)
        // Send to me
        const mySubId = await NotificationManager.getUserSubId('3307494534')
        PushAlertApi.sendMessage('New Registration', `Phone: ${phone}`, mySubId)
      } else {
        // Parent account does not exist
        setState({
          ...state,
          showAlert: true,
          alertMessage: `There is no account with the phone number ${parentPhone}. Please re-enter or have your parent register.`,
        })
        return false
      }

      SmsManager.send(parentPhone, SmsManager.getParentVerificationTemplate())
    }
  }

  //send verifcatyion code, check for parent
  const sendChildVerificationCode = () => {
    const isValidated = validateChildForm()
    if (isValidated) {
      SmsManager.send(parentPhone, SmsManager.getParentVerificationTemplate(userName, verificationCode))
      setVerificationCodeSent(true)
    }
  }

  const sendParentVerificationCode = () => {
    const isValidated = validateParentForm()
    if (isValidated) {
      SmsManager.send(phone, SmsManager.getRegistrationVerificationTemplate(userName, verificationCode))
      setVerificationCodeSent(true)
    }
  }

  // Submit parent
  const submit = async () => {
    const isValid = validateParentForm()
    if (isValid) {
      // Check for existing account
      // await DB.getTable(DB.tables.users).then((users) => {
      //   if (users.findIndex((x) => x.email === newUser.email || x.phone === newUser.phone) > -1) {
      //     setAlert("Account already exists, please login");
      //     setState({ ...state, showAlert: true });
      //     return false;
      //   }
      // });

      let childrenObjects = []

      children.forEach((childName) => {
        let child = new Child()
        child.general.address = child.address ?? ''
        child.general.phone = child.phoneNumber ?? ''
        child.general.name = childName
        child.general.gender = child.gender ?? ''
        childrenObjects.push(child)
      })

      let newUser = new User()
      newUser.id = Manager.getUid()
      newUser.email = email
      newUser.password = password
      newUser.name = userName
      newUser.accountType = 'parent'
      newUser.children = childrenObjects
      newUser.phone = phone.formatPhone()
      newUser.coparents = coparentsArr
      newUser.parentType = parentType
      newUser.eveningReminderSummaryHour = '8pm'
      newUser.morningReminderSummaryHour = '10am'
      const dbRef = ref(getDatabase())
      const subId = await NotificationManager.getUserSubId('3307494534')
      PushAlertApi.sendMessage('New Registration', `Phone: ${phone} | Name: ${userName}`, subId)
      await set(child(dbRef, `users/${newUser.phone}`), newUser)
      setState({ ...state, currentScreen: ScreenNames.login })
    }
  }

  const addChild = () =>
    setChildrenInputs([
      ...childrenInputs,
      <ChildrenInput childrenCount={childrenInputs.length + 1} onChange={(e) => setChildren([...children, e.target.value])} />,
    ])
  const handleParentType = (e) => Manager.handleCheckboxSelection(e, setParentType(null), setParentType(e.currentTarget.dataset.label))
  const handleCoparentType = (e) => Manager.handleCheckboxSelection(e)
  const addCoparent = () =>
    setCoparentInputs([...coparentInputs, <CoparentInputs coparentsLength={coparentInputs.length + 1} handleCoparentType={handleCoparentType} />])
  const [coparentInputs, setCoparentInputs] = useState([<CoparentInputs handleCoparentType={handleCoparentType} />])

  useEffect(() => {
    Manager.scrollToTopOfPage()
    Manager.showPageContainer()
    setState({ ...state, currentScreen: ScreenNames.registration, isLoading: false, goBackScreen: ScreenNames.login })
  }, [])

  return (
    <>
      <p className="screen-title ">Sign Up</p>
      <div id="registration-container" className="page-container" {...handlers}>
        {/* SET ACCOUNT TYPE */}
        {!accountType && (
          <>
            <label className="mb-10 block center-text">Account Type</label>
            <div className="button-group flex">
              <button className="button default w-50 mr-10" onClick={() => setAccountType('parent')}>
                Parent
              </button>
              <button className="w-50 default button" onClick={() => setAccountType('child')}>
                Child
              </button>
            </div>
          </>
        )}

        {/* CHILD VERIFICATION CODE INPUT */}
        {verificationCodeSent && accountType === 'child' && (
          <div className="form">
            <label>To ensure privacy and security, please enter the verification code from your parent</label>
            <input type="text" className="verification-code mb-20" onChange={(e) => setVerificationCode(e.target.value)} />
            <button className="button default green center" onClick={submitChild}>
              Register <span className="material-icons-round">person_add</span>
            </button>
          </div>
        )}

        {/* PARENT VERIFICATION CODE INPUT */}
        {verificationCodeSent && accountType === 'parent' && (
          <div className="form">
            <label>To ensure privacy and security, please enter the verification code you received via text message</label>
            <input type="text" className="verification-code mb-20" onChange={(e) => setVerificationCode(e.target.value)} />
            <button className="button default green center" onClick={submit}>
              Register <span className="material-icons-round">person_add</span>
            </button>
          </div>
        )}

        {/* CHILD FORM */}
        {accountType && accountType === 'child' && !verificationCodeSent && (
          <div className="form mb-20">
            <input className="mb-15" type="text" placeholder="Name*" onChange={(e) => setUserName(e.target.value)} />
            <input
              className="mb-15"
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Your phone number*"
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              className="mb-15"
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Phone number of parent that has app*"
              onChange={(e) => setParentPhone(e.target.value)}
            />
            <input className="mb-15" type="password" placeholder="Password*" onChange={(e) => setPassword(e.target.value)} />
            <input className="mb-15" type="password" placeholder="Confirm password*" onChange={(e) => setConfirmedPassword(e.target.value)} />
            <PasswordChecklist
              rules={['minLength', 'specialChar', 'number', 'capital', 'match', 'notEmpty']}
              minLength={5}
              value={password}
              valueAgain={confirmedPassword}
              onChange={(isValid) => {
                if (isValid) {
                  setPassword(password)
                }
              }}
            />

            <button className="button default green" onClick={sendChildVerificationCode}>
              Continue<span className="material-icons-outlined">arrow_forward</span>
            </button>
          </div>
        )}

        {/* PARENT FORM */}
        {accountType && accountType === 'parent' && !verificationCodeSent && (
          <div className="form mb-20">
            <label>Which type of parent are you?</label>
            <CheckboxGroup labels={['Biological Parent', 'Step-Parent']} onCheck={handleParentType} />
            <input className="mb-15" type="text" placeholder="Name *" onChange={(e) => setUserName(e.target.value)} />
            <input className="mb-15" type="email" placeholder="Email address *" onChange={(e) => setEmail(e.target.value)} />
            <input
              className="mb-15"
              type="number"
              pattern="[0-9]*"
              inputMode="numeric"
              placeholder="Phone number*"
              onChange={(e) => setPhone(e.target.value)}
            />
            <input className="mb-15" type="password" placeholder="Password *" onChange={(e) => setPassword(e.target.value)} />
            <input className="mb-15" type="password" placeholder="Confirm password *" onChange={(e) => setConfirmedPassword(e.target.value)} />
            <PasswordChecklist
              rules={['minLength', 'specialChar', 'number', 'capital', 'match', 'notEmpty']}
              minLength={5}
              value={password}
              valueAgain={confirmedPassword}
              onChange={(isValid) => {
                if (isValid) {
                  setPassword(password)
                }
              }}
            />
            {coparentInputs.map((input, index) => {
              return <span key={index}>{input}</span>
            })}
            <button id="add-coparent-button" className="button default w-60" onClick={addCoparent}>
              Add Another Co-Parent
            </button>
            <div className="children">
              {childrenInputs.map((input, index) => {
                return <span key={index}>{input}</span>
              })}
            </div>
            <button id="add-child-button" className="button default w-60" onClick={addChild}>
              Add Another Child
            </button>
            <button className="button default green" onClick={sendParentVerificationCode}>
              Continue<span className="material-icons-outlined">arrow_forward</span>
            </button>
          </div>
        )}
      </div>
    </>
  )
}
